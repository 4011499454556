import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  successDelete: false,
  data: {},
  itemId: '',
}

export const {
  requestDeleteSuggestion,
  successDeleteSuggestion,
  failureDeleteSuggestion,
  clearDeleteSuggestion,
} = createActions({
  REQUEST_DELETE_SUGGESTION: (itemId) => ({
    ...defaultState,
    fetching: true,
    itemId,
  }),
  SUCCESS_DELETE_SUGGESTION: (data, message) => ({
    data,
    message,
    fetching: false,
    error: false,
    successDelete: true,
  }),
  FAILURE_DELETE_SUGGESTION: (message) => ({
    message,
    fetching: false,
    error: true,
    successDelete: false,
  }),
  CLEAR_DELETE_SUGGESTION: () => ({
    ...defaultState,
  }),
})

const deleteSuggestion = handleActions(
  {
    [requestDeleteSuggestion]: produce((draft, { payload: { message, fetching, itemId } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.itemId = itemId
    }),
    [successDeleteSuggestion]: produce((draft, { payload: { message, fetching, successDelete, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.successDelete = successDelete
      draft.data = data
    }),
    [failureDeleteSuggestion]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
    }),
    [clearDeleteSuggestion]: produce(
      (draft, { payload: { message, fetching, error, data, successDelete } }) => {
        draft.message = message
        draft.fetching = fetching
        draft.error = error
        draft.data = data
        draft.successDelete = successDelete
      }
    ),
  },
  defaultState
)

export default deleteSuggestion
