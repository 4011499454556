import { put, takeLatest, call } from 'redux-saga/effects'
import { suggestions } from 'services'
import { requestCreateSuggestion, successCreateSuggestion, failureCreateSuggestion } from '../duck/createSuggestion.duck'

function* createSuggestionAsync(data) {
  try {
    const response = yield call(suggestions.saveSuggestions, data.payload.data)
    yield put(successCreateSuggestion(response))
  } catch (error) {
    yield put(failureCreateSuggestion(error.response.data.message))
  }
}

function* watchCreateRuleAsync() {
  yield takeLatest(requestCreateSuggestion, createSuggestionAsync)
}

export default watchCreateRuleAsync
