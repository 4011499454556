import { put, takeLatest, call } from 'redux-saga/effects'
import { suggestions } from 'services'
import { requestEditSuggestion, successEditSuggestion, failureEditSuggestion } from 'store/duck/editSuggestion.duck'

function* editSuggestionRequestAsync(data) {
  try {
    const response = yield call(suggestions.updateSuggestion, data.payload.data, data.payload.suggestionId)
    yield put(successEditSuggestion(response, 'success'))
  } catch (error) {
    yield put(failureEditSuggestion(error.response.data.message))
  }
}

function* watchEditSuggestionAsync() {
  yield takeLatest(requestEditSuggestion, editSuggestionRequestAsync)
}

export default watchEditSuggestionAsync
