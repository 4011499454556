import styled from 'styled-components'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'



export const StyledRow = styled(Row)({
  display: 'flex',
  alignItems: 'center',
  padding: '0px 5px',
  width: '100%',
  height: '40px',
  margin: '0px',
  color: '#002555',
  borderRadius: '4px',
  '&:hover': {
    background: '#3594FF',
    color: '#FFFFFF',
  },
})
  
export const StyledCol = styled(Col)({
  display: 'flex',
  alignItems: 'center',
  padding: '1px',
})

