/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useReducer, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector, batch, waitAllActions } from 'react-redux'
import { Form } from 'antd'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import { FormItem } from 'components/Input'
import { AiTwotoneCalendar } from 'react-icons/ai'
import Swal from 'sweetalert2'
import Box from 'components/Box/BoxGeneric'
import Button from 'components/Button/ButtonPrimary'
import ButtonQuaternary from 'components/Button/ButtonQuaternary'
import SpinLoading from 'components/SpinLoading'
import { requestSingleCustomer } from 'store/duck/getCustomers.duck'
import { requestGetAllCustomer } from 'store/duck/getAllCustomer.duck'
import { requestGetTruckTypes } from 'store/duck/getTruckTypes.duck'
import { requestGetSpecialServices } from 'store/duck/getSpecialServices.duck'
import { requestGetDefaultCustomer } from 'store/duck/getDefaultCustomer.duck'
import { saveServices, clearSpecialServices, updateSpecialService } from 'store/duck/saveSpecialServices.duck'
import {
  saveRate,
  saveTotalMilles,
  removeWayPoint,
  addWayPoint as addSpotWay,
  clearRate,
} from 'store/duck/saveQuote.duck'
import Grid from 'antd/lib/grid'
import {
  requestGetZipHere,
  saveRouteMarkers,
  clearGetZipHere,
  finishRate,
  resetRate,
} from 'store/duck/getZipHere.duck'
import TextPrimary from 'components/Text/TextPrimary'
import { requestGetRate, clearGetRate } from 'store/duck/getRate.duck'
import { clearCreateRate } from 'store/duck/createRate.duck'
import { requestGetRouteData, clearGetRouteData } from 'store/duck/getRouteData.duck'
import { saveSendEmail } from 'store/duck/sendEmail.duck'
import { requiredSimple } from 'utils/inputRules'
import { formatPoints } from 'utils/formatValues'
import useAutocomplete from 'hooks/useAutocomplete'
import { ArrowDownIcon } from 'assets/svg'
import ModalRates from 'components/ModalRates'
import { useLocation, useHistory } from 'react-router-dom'
import { GoAlert } from 'react-icons/go'
import Typography from 'antd/lib/typography'
import CustomSelect from 'components/CustomSelect'
import InputLineDatePicker from 'components/Input/InputLineDatePicker'
import LineSelectCity from 'components/Input/InputLineSelectCity/Index'
import InputLineContainer from 'components/Input/InputLineContainer'
import { IoMdArrowDropup, IoMdArrowDropdown } from 'react-icons/io'
import { requestUser } from 'store/duck/getSingleUser.duck'
import { convertToLane, getRouteMarkersValue, isValidForLane, totalMovementsFromRoutes } from 'utils/CreateRate.util'
import reducer from './utils/reducer'
// import matchServices from '../ListSpecialServices/utils/matchArrays'
import { BorderSelect, HiddenCol, ContainerButtonHidden } from './styles'
import ModalErrorRate from './ModalErrorRate'

const { useBreakpoint } = Grid

const GetRates = () => {
  const screens = useBreakpoint()
  const { xs, sm } = screens
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [form] = Form.useForm()
  const [deliveryState, setDeliveryState] = useState(false)
  const [totalCustomer, setTotalCustomers] = useState([])
  const [rateGenerated, setRateGenerated] = useState(false)
  const [pickupDate, setPickupDate] = useState(moment())
  const [weightController, setWeight] = useState(35000)
  const [deliveryDate, setDeliveryDate] = useState('')
  const [specialServicesState, setSpecialServicesState] = useState([])
  const [hidden, sethidden] = useState(false)
  const [queryCount, setQueryCount] = useState(0)
  const hightHidden = '35px'

  // const { success, role, login } = useSelector((state) => ({
  //   success: state.login.success,
  //   role: state.login.data.roleId ? state.login.data.roleId.name : '',
  //   login: state.login.data
  // }))

  const {
    customers,
    defaultCustomer,
    editRate,
    error,
    fetching,
    hereData,
    laneData,
    rateSelected,
    routeData,
    savedServices,
    services,
    singleCustomer,
    success,
    successSaveRate,
    truckTypes,
    message,
    dataZip,
    wayPoints,
    successZip,
    role,
    dataLogin,
    userData,
    userSuccess,
    refreshTokenError,
    ignoreSaveQuote
  } = useSelector((state) => ({
    customers: state.getAllCustomer.data || [],
    defaultCustomer: state.getDefaultCustomer.data,
    editRate: state.createRate.editRate,
    error: state.getRate.error,
    fetching: state.getRate.fetching,
    hereData: state.getZipHere,
    laneData: state.saveQuote.data,
    rateSelected: state.createRate.rateSelected,
    routeData: state.getRouteData,
    savedServices: state.saveSpecialServices,
    services: state.getSpecialServices.services,
    singleCustomer: state.getCustomers.singleCustomer,
    success: state.getRate.success,
    successSaveRate: state.saveQuote.success,
    truckTypes: state.getTruckTypes.trucktypes,
    message: state.getRate.message,
    dataZip: state.getZipHere.data,
    wayPoints: state.saveQuote.wayPoints,
    successZip: state.getZipHere.success,
    role: state.login.data.roleId ? state.login.data.roleId.name : '',
    dataLogin: state.login.data,
    userData: state.getSingleUser.data,
    userSuccess: state.getSingleUser.success,
    refreshTokenError: state.refreshToken.error,
    ignoreSaveQuote: state.saveQuote.ignore
  }))

  const isAdmin = role === 'Admin'
  const id = dataLogin?.id ?? ''
  const { customers: customersData } = userData || {}

  const [stopsRate, setStopsRate] = useState([])

  const [editEnter, setEditEnter] = useState(editRate)
  const [{ currentSpecialService, currentQuantity, specialServices, params, servicesParams }, setValues] =
    useReducer(reducer, {
      specialServices: editRate || success ? savedServices.data : [],
      customer: '',
      currentSpecialService: undefined,
      currentQuantity: 1,
      params: {},
      servicesParams: {},
    })

  const defaultValues = {
    origin: null,
    pickup: moment(),
    destination: null,
    delivery: '',
    weight: 35000,
    quantity: 1,
    names: ['', ''],
  }

  const [dataDestination, setPlaceDestination, handleSelect, fetchingData] = useAutocomplete()
  const [openError, setopenError] = useState(false)
  const { Text } = Typography
  const errorRefreshToken = window.localStorage.getItem("norefresh")

  const BodyError2 = ({ messageBody }) => {
    return (
      <Row
        gutter={[16, 14]}
        style={messageBody.length > 50 ? { display: 'inline' } : { padding: '37px 20px 20px 20px' }}
      >
        <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '45px',
              height: '45px',
              borderRadius: '8px',
              backgroundColor: '#F85359',
            }}
          >
            <GoAlert size={30} color="white" />
          </div>
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#545454',
            fontWeight: '400',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          <Text style={{ wordWrap: 'break-word' }}>
            {messageBody ===
              'Due to rules configurations the total rate is under DAT Contract, review and try again' ||
              messageBody === 'No rate information available for this request.' ||
              messageBody === 'Rates not available!'
              ? messageBody
              : `Error while executing the lane criteria. Details: ${messageBody}`}
          </Text>
        </Col>
      </Row>
    )
  }

  useEffect(() => {
    // if (isAdmin) {
    batch(() => {
      dispatch(requestGetAllCustomer({ active: true }))
      dispatch(requestGetDefaultCustomer())
      dispatch(requestGetTruckTypes())
      dispatch(requestGetSpecialServices(servicesParams))
    })
    // } else {
    //   batch(() => {
    //     dispatch(requestGetTruckTypes())
    //     dispatch(requestGetSpecialServices(servicesParams))
    //   })
    // }
    if (id !== '' && !isAdmin) {
      dispatch(requestUser(id))
    }
  }, [dispatch, servicesParams, isAdmin, id])

  useEffect(() => {
    if (customersData) {
      if (customersData.length > 0 && !isAdmin) {
        form.setFieldsValue({ customer: customersData[0].id.name })
        const formatSingleCustomer = (item = {}) => {
          return {
            id: item?.id?._id,
            key: item?.id?._id,
            value: item?.id?.name,
            flat: item?.id?.flat,
            margin: item?.id?.margin,
            email: item?.id?.email,
            consider: item?.id?.considerCustomFuelPrice,
            fuelPrice: item?.id?.customFuelPrice,
          }
        }
        dispatch(requestSingleCustomer(formatSingleCustomer(customersData[0])))
        setTotalCustomers(
          customersData.map((item) => {
            return {
              id: item.id._id,
              key: item.id._id,
              value: item.id.name,
              flat: item.id.flat,
              margin: item.id.margin,
              email: item.id.email,
              consider: item.id.considerCustomFuelPrice,
              fuelPrice: item.id.customFuelPrice,
            }
          })
        )
      } else {
        setTotalCustomers(customers)
      }

    }
    if (isAdmin) setTotalCustomers(customers)
  }, [customers, customersData, isAdmin, form, dispatch])

  useEffect(() => {
    if (customers.find((entry) => entry.value === defaultCustomer.value)) return
    const _customers = [...customers]
    if (defaultCustomer?.value) _customers.push(defaultCustomer)
    if (defaultCustomer?.value) setTotalCustomers(_customers)
  }, [customers, defaultCustomer])

  useEffect(() => {
    if (isAdmin) {
      const _default = form
        ?.getFieldInstance('customer')
        ?.props.options.find((entry) => entry.value === singleCustomer.value)
      if (_default === undefined || Object.keys(_default).length === 0) return
      dispatch(requestSingleCustomer(_default))
    }
  }, [form, defaultCustomer, dispatch, singleCustomer, isAdmin])

  useEffect(() => {
    dispatch(saveServices(specialServices))
  }, [specialServices, dispatch])

  function returnEntity(name, markersParam) {
    return markersParam.filter((ele) => ele?.content?.type === name)[0] ?? {}
  }

  function fullNameCity(obj) {
    const city = obj?.content?.city ?? ''
    const zipCode = obj?.content?.zipCode ?? ''
    if (city !== '' && zipCode !== '') {
      return `${obj?.content?.city ?? ''} - ${obj?.content?.zipCode ?? ''}`
    }
    return ''
  }

  function formatStops(st = []) {
    return st.map((item) => {
      return fullNameCity(item)
    })
  }

  const origin = fullNameCity(returnEntity('Origin', hereData.markers))
  const destination = fullNameCity(returnEntity('Destination', hereData.markers))
  const stopPoints = formatStops(hereData.stopMarkers)

  useEffect(() => {
    if (editRate && editEnter) {
      let names = []
      if (stopPoints.length > 0) {
        names = [origin, ...stopPoints, destination]
      } else {
        names = [origin, destination]
      }
      const loadRate = { ...rateSelected }
      form.setFieldsValue({
        customer: loadRate.customer.name,
        pickup: moment(loadRate.pickupDate),
        delivery: moment(loadRate.deliveryDate),
        truckTypeId: loadRate.truckTypeId._id,
        weight: loadRate.weight,
        names,
        origin,
        destination,
      })
      setEditEnter(false)
    }
    if (!editRate && success && !editEnter && Object.keys(laneData).length > 0) {
      if (laneData?.lane) {
        const cityOrigin = laneData?.lane?.origin?.city ?? ""
        const zipCodeorigin = laneData?.lane?.origin?.zipCode ?? ""
        const originFormatValid = cityOrigin !== "" && zipCodeorigin !== ""
        const originLaneCityWithZip = `${cityOrigin} - ${zipCodeorigin}`
        const destinationLaneCityWithZip = `${laneData?.lane?.destination?.city ?? ""} - ${laneData?.lane?.destination?.zipCode ?? ""}`
        if (originFormatValid) {
          dispatch(requestGetZipHere(`${laneData.lane.origin.city} ${laneData.lane.origin.zipCode}`, 0))
        }

        const cityDestination = laneData?.lane?.destination?.city ?? ""
        const zipCodeDestination = laneData?.lane?.destination?.zipCode ?? ""
        const destinationFormatValid = cityDestination !== "" && zipCodeDestination !== ""

        form.setFieldsValue({
          customer: laneData.customerName,
          weight: laneData.weight,
          origin: originFormatValid ? originLaneCityWithZip : null,
          destination: destinationFormatValid ? destinationLaneCityWithZip : null,
          delivery: moment(laneData.deliveryDate),
          pickup: moment(laneData.pickupDate),
          truckTypeId: laneData.truckTypeId,
        })
        setEditEnter(true)
      }

    }
  }, [
    dispatch,
    defaultValues,
    editRate,
    success,
    form,
    rateSelected,
    laneData,
    handleSelect,
    editEnter,
    origin,
    stopPoints,
    destination,
  ])

  useEffect(() => {
    if (error && message !== '') {
      setopenError(true)
    }
  }, [dispatch, error, message])

  const closeModalCleanRate = () => {
    dispatch(clearGetRate())
    setopenError(false)
  }

  useEffect(() => {
    if (Object.keys(defaultCustomer).length === 0) return
    if (!editRate && !success && !editEnter) {
      form.setFieldsValue({ customer: defaultCustomer.value })
      setEditEnter(true)
    }
  }, [defaultCustomer, form, editEnter, editRate, success])

  useEffect(() => {

    if (routeData.success && routeData.data.routes.length > 0) {
      const spotPoints = []
      const rateData = { ...params }
      // const { truckTypeId, lane } = rateData
      // const greenScreenParams = { lane, truckTypeId }
      // dispatch(requestGetGsRate(greenScreenParams))

      let totalMilles = 0
      const totalMovements = totalMovementsFromRoutes(routeData)
      if (totalMovements > 0) {
        routeData.data.routes[0].sections.map((item, index) => {
          const { duration, length: totalDistance } = item.summary
          const travelDistance = parseInt(totalDistance / 1609.34, 10)
          const travelTime = parseInt(duration / 3600, 10)
          totalMilles += travelDistance
          const addWayPoint = { milles: travelDistance, travelTime }
          if (index === 0) {
            addWayPoint.origin = {
              city: getRouteMarkersValue(hereData, index, ["content", "city"]),
              zipCode: getRouteMarkersValue(hereData, index, ["content", "zipCode"]),
              coordinate: getRouteMarkersValue(hereData, index, ["coordinate"]),
            }
            if (totalMovements > 1) {
              addWayPoint.destination = {
                city: getRouteMarkersValue(hereData, 2, ["content", "city"]),
                zipCode: getRouteMarkersValue(hereData, 2, ["content", "zipCode"]),
                coordinate: getRouteMarkersValue(hereData, 2, ["coordinate"]),
              }
            } else {
              addWayPoint.destination = {
                city: getRouteMarkersValue(hereData, 1, ["content", "city"]),
                zipCode: getRouteMarkersValue(hereData, 1, ["content", "zipCode"]),
                coordinate: getRouteMarkersValue(hereData, 2, ["coordinate"]),
              }
            }
          }
          if (index > 0 && index === totalMovements - 1) {
            addWayPoint.origin = {
              city: getRouteMarkersValue(hereData, totalMovements, ["content", "city"]),
              zipCode: getRouteMarkersValue(hereData, totalMovements, ["content", "zipCode"]),
              coordinate: getRouteMarkersValue(hereData, totalMovements, ["coordinate"]),
            }
            // Donaldo Llanos 4 years ago (March 4th, 2021 9:33 AM)  Fixing break of application when places are outside USA
            addWayPoint.destination = {
              city: getRouteMarkersValue(hereData, 1, ["content", "city"]),
              zipCode: getRouteMarkersValue(hereData, 1, ["content", "zipCode"]),
              coordinate: getRouteMarkersValue(hereData, 1, ["coordinate"]),
            }
          }
          if (index > 0 && index < totalMovements - 1) {
            addWayPoint.origin = {
              // Donaldo Llanos 4 years ago (March 4th, 2021 9:33 AM)  Fixing break of application when places are outside USA          
              city: getRouteMarkersValue(hereData, index + 1, ["content", "city"]),
              zipCode: getRouteMarkersValue(hereData, index + 1, ["content", "zipCode"]),
              coordinate: getRouteMarkersValue(hereData, index + 1, ["coordinate"]),
            }
            addWayPoint.destination = {
              // Donaldo Llanos 4 years ago (March 4th, 2021 9:33 AM)  Fixing break of application when places are outside USA          
              city: getRouteMarkersValue(hereData, index + 2, ["content", "city"]),
              zipCode: getRouteMarkersValue(hereData, index + 2, ["content", "zipCode"]),
              coordinate: getRouteMarkersValue(hereData, index + 2, ["coordinate"])
            }
          }
          spotPoints.push(addWayPoint)
          return spotPoints
        })
      }

      rateData.totalStops = hereData.stopMarkers.length
      rateData.totalMilles = totalMilles
      dispatch(saveTotalMilles(totalMilles, rateData.totalStops, spotPoints))
      rateData.stops = JSON.stringify(stopsRate)

      const valuesForm = form.getFieldsValue()
      const formatValue = convertToLane(valuesForm.origin, valuesForm.destination)
      
      if (isValidForLane(valuesForm.origin) && isValidForLane(valuesForm.destination)) {
        if (formatValue !== rateData.lane) {
          rateData.lane = formatValue
        }
      } else {
        return
      }

      dispatch(requestGetRate(rateData))

      dispatch(clearGetRouteData())
    }
  }, [dispatch, success, routeData, params, hereData, stopsRate])

  useEffect(() => {
    if (
      routeData.success &&
      !routeData.fetching &&
      routeData.data.notices &&
      routeData.data.notices.length > 0 &&
      routeData.data.routes &&
      routeData.data.routes.length < 1
    ) {
      Swal.fire({
        icon: 'error',
        title: 'There are not available routes for these points',
      })
    }
  }, [success, routeData])

  useEffect(() => {
    if (rateGenerated) {
      setTimeout(() => {
        setRateGenerated(false)
      }, 4200)
    }
  }, [rateGenerated])

  useEffect(() => {
    if (successSaveRate && ignoreSaveQuote === false) {
      dispatch(clearCreateRate())
      dispatch(clearGetZipHere())
      dispatch(finishRate())
      dispatch(clearSpecialServices())
      dispatch(removeWayPoint([]))
      dispatch(requestSingleCustomer(defaultCustomer))
      setValues({ type: 'remove-special-services' })
      setDeliveryDate(false)
      form.setFieldsValue({ ...defaultValues, customer: defaultCustomer.value })
      dispatch(clearRate())
      history.push('/dashboard')
    }
  }, [successSaveRate, dispatch, defaultCustomer, form, defaultValues, history, ignoreSaveQuote])

  const waitForCondition = (condition, interval = 100, attempts = 10) => {
    let counter = attempts;
    return new Promise((resolve, reject) => {
      const checkCondition = () => {
        if (condition() || counter === 0) {
          resolve();
        } else {
          counter -=1;
          setTimeout(checkCondition, interval);
        }
      };
      checkCondition();
    });
  }

  const handleFinish = async(values) => {
    await waitForCondition(()=> queryCount === 0, 50,10);
    dispatch(clearGetRate())
    setRateGenerated(true)
    setStopsRate(formatPoints(values.names))
    const pickupDateFormated = moment(values.pickup).format('YYYY/MM/DD')
    // eslint-disable-next-line no-extra-boolean-cast
    const deliveryDay = !!values.delivery
      ? moment(values.delivery).format('YYYY/MM/DD')
      : moment(pickupDateFormated).add(3, 'days').format('YYYY/MM/DD')
    form.setFieldsValue({
      delivery: moment(deliveryDay),
    })
    const customer = role === "Lite" ? defaultCustomer.id : singleCustomer.id || defaultCustomer.id
    const rateParams = {
      customer,
      lane: JSON.stringify(laneData.lane),
      pickupDate: pickupDateFormated,
      deliveryDate: deliveryDay,
      truckTypeId: values.truckTypeId,
      weight: weightController,
    }
    if (!singleCustomer.id) {
      dispatch(requestSingleCustomer(defaultCustomer))
    }

    if (savedServices.data.length > 0) rateParams.specialServices = JSON.stringify(savedServices.data)
    let routeMarkers = []
    if (hereData.stopMarkers === 0) routeMarkers = [...hereData.markers]
    else routeMarkers = [...hereData.markers, ...hereData.stopMarkers]
    values.delivery = moment(deliveryDay)
    dispatch(saveRouteMarkers(routeMarkers))
    dispatch(
      saveRate(
        { ...values, weight: weightController },
        savedServices.data,
        singleCustomer.id ?? defaultCustomer.id
      )
    )
    let routeParams = ''
    const originLatitudMarket = getRouteMarkersValue(hereData, 0, ["coordinate", "lat"], "markers")
    const originLongitudMarket = getRouteMarkersValue(hereData, 0, ["coordinate", "lng"], "markers")
    const destinationLatitudMarket = getRouteMarkersValue(hereData, 1, ["coordinate", "lat"], "markers")
    const destinationLongitudMarket = getRouteMarkersValue(hereData, 1, ["coordinate", "lng"], "markers")

    const originLatitudRouteMarkers = getRouteMarkersValue(hereData, 0, ["coordinate", "lat"])
    const originLongitudRouteMarkers = getRouteMarkersValue(hereData, 0, ["coordinate", "lng"])
    const destinationLatitudRouteMarkers = getRouteMarkersValue(hereData, 1, ["coordinate", "lat"])
    const destinationLongitudRouteMarkers = getRouteMarkersValue(hereData, 1, ["coordinate", "lng"])

    const originLat = originLatitudMarket || originLatitudRouteMarkers
    const originLng = originLongitudMarket || originLongitudRouteMarkers
    const destinationLat = destinationLatitudMarket || destinationLatitudRouteMarkers
    const destinationLng = destinationLongitudMarket || destinationLongitudRouteMarkers


    routeParams += `origin=${originLat},${originLng}`
    routeParams += `&destination=${destinationLat},${destinationLng}`

    hereData.stopMarkers.map((item) => {
      let lat = ""
      let lng = ""
      if (item.coordinate) {
        if (item.coordinate.lat) {
          lat = item.coordinate.lat
        }
      }
      if (item.coordinate) {
        if (item.coordinate.lng) {
          lng = item.coordinate.lng
        }
      }
      routeParams += `&via=${lat},${lng}`
      return routeParams
    })
    dispatch(requestGetRouteData({ routeParams }))
    setValues({ type: 'rate-params', params: rateParams })
    const stops = []
    hereData.stopMarkers.map((entry) => stops.push(entry?.content?.city ?? ""))

    dispatch(
      saveSendEmail({
        customer,
        customerEmail: singleCustomer.email ?? defaultCustomer.email,
        pickupDate: moment(values.pickup).format('YYYY/MM/DD'),
        deliveryDate: moment(values.delivery).format('YYYY/MM/DD'),
        origin,
        destination,
        equipmentType: truckTypes.find((entry) => entry._id === values.truckTypeId).name,
        weight: weightController,
        totalSpecialServices: savedServices.data.length,
        stops,
      })
    )
    if (successZip && Object.keys(dataZip).length > 0 && dataZip.wayPoint) {
      let isValid = true
      wayPoints.map((item) => {
        if (item.city === dataZip.wayPoint.city) isValid = false
        return isValid
      })
      if (isValid) dispatch(addSpotWay(dataZip))
    }
  }

  // useEffect(() => {
  //   const active = matchServices(services, savedServices.data)
  //   setSpecialServicesState(active)
  // }, [dispatch, savedServices.data, services])

  // const handleAddService = () => {
  //   const actualQuantity = currentQuantity === '' ? form.getFieldValue('quantity') : currentQuantity

  //   const tags = specialServicesState.map((tag) => {
  //     if (tag.name === currentSpecialService.name) {
  //       return { ...tag, quantity: actualQuantity + 1 }
  //     }

  //     return tag
  //   })
  //   const currentTag = specialServicesState.find((tag) => tag.name === currentSpecialService.name)
  //   if (currentTag) {
  //     setSpecialServicesState(tags)
  //   } else {
  //     setSpecialServicesState([...specialServicesState, { ...currentSpecialService, quantity: actualQuantity }])
  //   }

  //   if (currentSpecialService !== undefined) {
  //     setValues({
  //       type: 'addService',
  //       payload: { id: currentSpecialService.id, quantity: actualQuantity },
  //     })
  //     form.setFieldsValue({ services: null, quantity: defaultValues.quantity })
  //   }
  // }

  // const handleServiceUpdate = (updatedServices) => {
  //   setValues({ type: 'remove-special-services' })
  //   updatedServices.forEach((service) => {
  //     setValues({
  //       type: 'addService',
  //       payload: { id: service.id, quantity: service.quantity },
  //     })
  //   })
  // }

  // const mathHandler = (index) => {
  //   const newData = [...specialServicesState]
  //   const service = { ...specialServicesState[index] }

  //   service.quantity = Number(service.quantity) - 1
  //   if (service.quantity >= 1) {
  //     newData[index] = service
  //   } else {
  //     newData.splice(index, 1)
  //   }

  //   setSpecialServicesState(newData)
  //   dispatch(updateSpecialService(newData))
  //   handleServiceUpdate(newData)
  // }

  // const handleCloseTag = (removedTag, index) => {
  //   mathHandler(index)
  // }

  const handleReset = () => {
    dispatch(clearGetRate())
    dispatch(clearCreateRate())
    dispatch(clearGetZipHere())
    dispatch(resetRate())
    dispatch(finishRate())
    dispatch(clearSpecialServices())
    dispatch(removeWayPoint([]))
    dispatch(requestSingleCustomer(defaultCustomer))
    setValues({ type: 'remove-special-services' })
    setDeliveryDate(false)
    dispatch(clearRate())
    form.setFieldsValue({ ...defaultValues, customer: defaultCustomer.value })
    history.replace({ pathname: location.pathname, state: {} })
  }

  const onPickupDateChange = () => {
    setDeliveryState(false)
    setPickupDate(form.getFieldValue('pickup'))
  }

  const onDeliveryDateChange = () => {
    setDeliveryDate(form.getFieldValue('delivery'))
  }

  const disabledDeliveryDates = (current) => {
    return current && current < pickupDate
  }

  const disabledPickupDates = (current) => {
    if (deliveryDate) {
      return current && (current > deliveryDate || current < moment().subtract(3, 'months'))
    }
    return current < moment().subtract(3, 'months')
  }

  const handleCity = (value, index) => {
    setQueryCount(queryCount+1)
    dispatch(requestGetZipHere(value, index))
    setQueryCount(queryCount-1)
  }

  useEffect(() => {
    if (!form.getFieldValue("truckTypeId")) {
      if (truckTypes.length > 0) {
        form.setFieldsValue({ truckTypeId: truckTypes.find((entry) => entry.name === 'Van')?._id })
      }
    }
  }, [form, truckTypes])

  useEffect(() => {
    if (errorRefreshToken === "true") {
      setopenError(false)
    }
  }, [errorRefreshToken])


  return (
    <Box height="auto" title="Lane" marginTop="0px">
      <ModalRates messageBody="Please wait while the rate is calculated!" visible={rateGenerated} />
      {message === '' && errorRefreshToken ? null : (
        <ModalErrorRate
          messageBody={message}
          visible={openError}
          onCancel={closeModalCleanRate}
          CustomBody={BodyError2}
        />
      )}
      <Form
        form={form}
        onFinish={handleFinish}
        initialValues={{
          ...defaultValues,
        }}
        layout="vertical"
        name="createRate"
        id="createRate"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} xl={{ span: 3 }} xxl={{ span: 3 }}>
            <FormItem
              data-testid="truckType"
              name="truckTypeId"
              initialValue={truckTypes.find((entry) => entry.name === 'Van')?._id}
              rules={requiredSimple}
            >
              <BorderSelect
                bordered={false}
                style={{ width: '100%', borderRadius: '4px' }}
                placeholder="Select"
                showSearch
                suffixIcon={<ArrowDownIcon />}
                disabled={fetching || editRate}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {truckTypes.map((entry) => (
                  <BorderSelect.Option key={entry._id} value={entry._id}>
                    {entry.name}
                  </BorderSelect.Option>
                ))}
              </BorderSelect>
            </FormItem>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
            <LineSelectCity
              dataTestid="Origin"
              name="origin"
              label="Origin"
              onSearch={setPlaceDestination}
              options={dataDestination}
              disabled={fetching || editRate}
              onChange={handleCity}
              index={0}
              fetchingData={fetchingData}
            />
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }}>
            <LineSelectCity
              dataTestid="Destination"
              name="destination"
              label="Destination"
              onSearch={setPlaceDestination}
              options={dataDestination}
              disabled={fetching || editRate}
              onChange={handleCity}
              index={1}
              fetchingData={fetchingData}
            />
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }} xl={{ span: 5 }} xxl={{ span: 5 }}>
            <Row justify="end" gutter={[16, { xs: 16, sm: 16, md: 8, lg: 8 }]}>
              <Col span={24}>{ }</Col>
              <Col span={12}>
                <Button width="100%" type="primary" form="createRate" htmlType="submit" disabled={fetching}>
                  {fetching ? <SpinLoading /> : 'Get Rate'}
                </Button>
              </Col>
              <Col span={12}>
                <ButtonQuaternary width="100%" onClick={handleReset}>
                  Cancel
                </ButtonQuaternary>
              </Col>
            </Row>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 2 }} xl={{ span: 2 }} xxl={{ span: 2 }} style={role === "Lite" ? { display: "none" } : {}}>
            <ContainerButtonHidden
              style={{ display: 'flex', alignItems: 'center', margin: '0% 0% -10% 5%', paddingTop: '10px' }}
            >
              <TextPrimary style={{ fontSize: '16px' }} onClick={() => sethidden((prev) => !prev)}>
                {hidden ? 'Less' : 'More'}
              </TextPrimary>
              {hidden ? (
                <IoMdArrowDropup onClick={() => sethidden((prev) => !prev)} size={30} />
              ) : (
                <IoMdArrowDropdown onClick={() => sethidden((prev) => !prev)} size={30} />
              )}
            </ContainerButtonHidden>
          </Col>
          <Col span={24} style={xs || sm ? { marginTop: '19px' } : {}}>
            <Row data-testid="Collapse">
              <HiddenCol
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                xl={{ span: 6 }}
                xxl={{ span: 6 }}
                style={!hidden ? {} : { height: hightHidden, visibility: 'visible' }}
              >
                <InputLineContainer
                  marginComponent="0% 0% 0% 2%"
                  widthComponent="75%"
                  marginLabel="5px 0px 0px 0px"
                  label="Pick up Date"
                  Component={
                    <FormItem name="pickup" rules={requiredSimple}>
                      <InputLineDatePicker
                        bordered={false}
                        style={{
                          width: '100%',
                        }}
                        allowClear
                        disabled={fetching || editRate}
                        onChange={onPickupDateChange}
                        disabledDate={disabledPickupDates}
                        suffixIcon={<AiTwotoneCalendar />}
                        format="MM/DD/YYYY"
                      />
                    </FormItem>
                  }
                />
              </HiddenCol>
              <HiddenCol
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                xl={{ span: 6 }}
                xxl={{ span: 6 }}
                style={!hidden ? {} : { height: hightHidden, visibility: 'visible' }}
              >
                <InputLineContainer
                  marginComponent="0% 0% -3% 0%"
                  widthComponent="75%"
                  label="Delivery Date"
                  marginLabel="5px 0px 0px 0px"
                  Component={
                    <FormItem name="delivery">
                      <InputLineDatePicker
                        bordered={false}
                        style={{ width: '100%' }}
                        allowClear
                        disabled={fetching || deliveryState || editRate}
                        disabledDate={disabledDeliveryDates}
                        onChange={onDeliveryDateChange}
                        suffixIcon={<AiTwotoneCalendar />}
                        format="MM/DD/YYYY"
                      />
                    </FormItem>
                  }
                />
              </HiddenCol>
              <HiddenCol
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                xl={{ span: 6 }}
                xxl={{ span: 6 }}
                style={!hidden ? {} : { height: hightHidden, visibility: 'visible' }}
              >
                <InputLineContainer
                  label="Customer"
                  marginLabel="5px 0px 0px 0px"
                  marginComponent="-1% 0% 0% 2%"
                  Component={
                    <FormItem name="customer" rules={requiredSimple}>
                      <CustomSelect
                        Custom={BorderSelect}
                        bordered={false}
                        onSearch={(value) => {
                          dispatch(requestGetAllCustomer({ text: value, active: true }))
                        }}
                        showSearch
                        placeholder="Customer"
                        onChange={(value, entry) => {
                          dispatch(requestSingleCustomer(entry))
                        }}
                        disabled={fetching || editRate}
                        suffixIcon={<ArrowDownIcon />}
                        options={totalCustomer}
                      />
                    </FormItem>
                  }
                />
              </HiddenCol>
              {/* <Col span={2}>
                <ContainerButtonHidden
                  style={
                    hidden
                      ? { display: 'flex', alignItems: 'center', margin: '0% 0% -10% 5%', paddingTop: '10px' }
                      : { display: 'flex', alignItems: 'center', margin: '0% 0% -10% 5%' }
                  }
                >
                  <TextPrimary style={{ fontSize: '16px' }} onClick={() => sethidden((prev) => !prev)}>
                    {hidden ? 'Less' : 'More'}
                  </TextPrimary>
                  {hidden ? (
                    <IoMdArrowDropup onClick={() => sethidden((prev) => !prev)} size={30} />
                  ) : (
                    <IoMdArrowDropdown onClick={() => sethidden((prev) => !prev)} size={30} />
                  )}
                </ContainerButtonHidden>
              </Col> */}
            </Row>
          </Col>

          {/* <Container>
             
            <Col span={12}>
              <FloatLabel label="Weight" show>
                <FormItem name="weight" rules={requiredWeight}>
                  <InputNumber
                    style={{ width: '100%', borderRadius: '4px' }}
                    value={weightController}
                    onChange={(value) => {
                      setWeight(value)
                    }}
                    disabled={fetching || editRate}
                  />
                </FormItem>
              </FloatLabel>
            </Col>
          </Container>
          <SpecialService>
            <Col span={12}>
              <LabelForm>Add special services</LabelForm>
            </Col>
            <Container>
              <Col span={12} style={{ width: '160px' }}>
                <FloatLabel label="Special Services" show>
                  <FormItem name="services">
                    <CustomSelect
                      style={{ width: '100%', color: '#545454' }}
                      onSearch={(value) => {
                        dispatch(requestGetSpecialServices({ text: value, stops: 'false' }))
                      }}
                      showSearch
                      placeholder="Select a service"
                      onChange={() => setValues({ type: 'clearSpecial' })}
                      onSelect={(value, entry) => {
                        setValues({
                          type: 'addCurrentService',
                          payload: { data: entry, quantity: currentQuantity },
                        })
                      }}
                      options={services.filter((service) => service.active === true)}
                      disabled={fetching || editRate}
                      suffixIcon={<ArrowDownIcon />}
                    />
                  </FormItem>
                </FloatLabel>
              </Col>
              <Col span={10}>
                <FloatLabel label="Quantity" show>
                  <FormItem name="quantity" rules={requiredQuantity}>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={currentQuantity}
                      min={1}
                      max={99}
                      disabled={fetching || editRate || currentSpecialService === undefined}
                      onChange={(value) => setValues({ type: 'addCurrentQuantity', payload: { data: value } })}
                    />
                  </FormItem>
                </FloatLabel>
              </Col>
              <Col span={2}>
                <FormItem>
                  <Tooltip title="Add a new special service">
                    <AButton
                      type="primary"
                      size="small"
                      style={{ backgroundColor: '#3594FF', borderRadius: '4px' }}
                      disabled={currentSpecialService === undefined || currentQuantity === null}
                      onClick={() => handleAddService()}
                      icon={
                        <AiOutlinePlus
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                          }}
                        />
                      }
                    />
                  </Tooltip>
                </FormItem>
              </Col>
            </Container>
          </SpecialService>
          <Col span={24}>
            <LabelForm>Special services added</LabelForm>
            <SpecialServiceList>
              {specialServicesState.map((item, index) => (
                <StyledTag>
                  <div style={{ display: 'flex' }}>
                    <span
                      style={{
                        width: '100px',
                        overflow: 'hidden',
                        display: 'inline-block',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {item.name}.
                    </span>
                    ({item.quantity})
                  </div>
                  <IoMdClose
                    onClick={() => handleCloseTag(item, index)}
                    style={{
                      backgroundColor: '#bdc8d5',
                      color: '#002555',
                      marginLeft: '5px',
                      cursor: 'pointer',
                    }}
                  />
                </StyledTag>
              ))}
            </SpecialServiceList>
          </Col> */}
        </Row>
      </Form>
    </Box>
  )
}

export default GetRates
