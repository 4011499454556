import React from 'react'
import StyledTabs from 'components/Tabs'
import ServicesList from 'containers/settings/ServicesList'
import TruckTypeList from 'containers/settings/TruckTypeList'
import TitleList from 'components/TitleList'
import UserListPage from 'pages/User/UserCreate/UserListPage'
import StyledDiv from './styles'
import TrustPercentageList from '../TrustPercentageList'
import Reporting from '../Reporting'
import Feedback from '../Feedback'
// import Users

export default function Tabs() {
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ margin: '0px', padding: '0px' }}>
        <StyledDiv>
          <TitleList title="General Settings" />
        </StyledDiv>
        <StyledTabs
          height="100%"
          defaultActiveKey="1"
          tabBarStyle={{ width: '96%', margin: '0px auto', color: '#002555', fontWeight: 600, marginBottom: 15 }}
        >
          <StyledTabs.TabPane tab="Users" key="1">
            <UserListPage />
          </StyledTabs.TabPane>
          <StyledTabs.TabPane tab="Rate Source ‘Weighting’" key="2">
            <TrustPercentageList />
          </StyledTabs.TabPane>
          <StyledTabs.TabPane tab="Equipment Type" key="3">
            <TruckTypeList />
          </StyledTabs.TabPane>
          <StyledTabs.TabPane tab="Special Services" key="4">
            <ServicesList />
          </StyledTabs.TabPane>
          <StyledTabs.TabPane tab="Reporting" key="5">
            <Reporting />
          </StyledTabs.TabPane>
          <StyledTabs.TabPane tab="Feedback" key="6">
            <Feedback />
          </StyledTabs.TabPane>
        </StyledTabs>
      </div>
    </div>
  )
}
