import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FaUsers } from 'react-icons/fa'
import { FiLogOut, FiMessageCircle} from 'react-icons/fi'
import { removeStorage } from 'utils/storage'
import { useHistory } from 'react-router-dom'
import { logout } from 'store/duck/login.duck'
import { clearGetRate } from 'store/duck/getRate.duck'
import socket from 'utils/socketManager'
import Menu from 'antd/lib/menu'
import Dropdown from 'antd/lib/dropdown'
import { StyledItem } from './styles'
import ModalUserProfile from '../ModalUserProfile'
import ModalFeedback from '../ModalFeedback'
import ModalLogout from './ModalLogOut'

const ProfileMenu = ({ children }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [visibleModal, setVisibleModal] = useState(false)

  const handleCloseSession = () => {
    dispatch(logout())
    dispatch(clearGetRate())
    removeStorage('altptk')
    removeStorage('altprtk')
    removeStorage('altpntk')
    removeStorage('expiration')
    window.localStorage.setItem('sleeping', false)
    socket.disconnect()
    history.push('/login')
  }

  const handleShowModal = () => {
    setVisibleModal(!visibleModal)
  }

  const [visible, setVisible] = useState(false)
  const [Feedback, setFeedback] = useState(false)
  const menu = (
    <Menu style={{ width: '120px', marginTop: '10px', borderRadius: '10px !important' }}>
      <StyledItem onClick={() => setVisible(true)}>
        <FaUsers fontSize="20px" style={{ marginRight: '5px' }} /> Profile
      </StyledItem>
      <StyledItem onClick={() => setFeedback(true)}>
        <FiMessageCircle fontSize="20px" style={{ marginRight: '5px'}} /> Feedback
      </StyledItem>
      <StyledItem onClick={handleShowModal}>
        <FiLogOut fontSize="20px" style={{ marginRight: '5px' }} />
        Log Out
      </StyledItem>
    </Menu>
  )
  return (
    <>
      <ModalUserProfile visible={visible} close={setVisible} />
      {Feedback && <ModalFeedback visible={Feedback} close={setFeedback} />}
      <ModalLogout visible={visibleModal} close={handleShowModal} logout={handleCloseSession} />
      <Dropdown overlay={menu}>{children}</Dropdown>
    </>
  )
}

export default ProfileMenu
