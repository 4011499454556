import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Checkbox from 'antd/lib/checkbox'
import { DatePicker, Select } from 'antd/lib'
import Input from 'antd/lib/input'
import Text from 'antd/lib/typography/Text'
import moment from 'moment'
import { FiPauseCircle } from 'react-icons/fi'
import colors from 'providers/theme/config/colorPallete'
import { requestGetTruckTypeList } from 'store/duck/getTruckTypeList.duck'
import { descriptionRules, stringRules } from 'utils/inputRules'
import handleMarketState from 'utils/handleMarketState'
import { useDispatch, useSelector } from 'react-redux'
import { requestCreateRule, clearData, startCreateRule } from 'store/duck/createRule.duck'
import { requestUpdateRule, clearUpdateRule } from 'store/duck/updateRule.duck'
import SelectOption from 'components/SelectOption'
import DrawerItem from 'components/Form/DrawerItem'
import { InputGeneric } from 'components/Input'
import FloatLabel from 'components/Label/FloatLabel'
import NumberInput from 'components/Form/NumerInput'
import InputWithLabel from 'components/Form/InputWithLabel'
import AddToRulesetsModal from 'components/AddToRulesetsModal'
import useAutocompleteState from 'hooks/useAutocompleteState'
import { EnumStatus } from 'utils/getStatus'
import { requestGetMarkets, clearGetMarkets } from 'store/duck/getMarkets.duck'
import {
  RULE_GEOGRAPHIC,
  RULE_SIMPLE,
  RULE_DATE_RANGE,
  RULE_LENGTH,
  EQUIPMENT_TYPE,
  RULE_MARKET,
  RULE_LANE_SCORE,
  RULE_LEAD_TIME,
  RULE_REGION,
  RULE_MCI,
  RULE_LTRATIO,
} from 'utils/constants'
import notify from 'utils/notify'
import days from 'utils/days.json'
import jsonDiff from 'utils/jsonDiff'
import CreateMultistepRuleModal from 'containers/rules/ListMultiStep/utils/CreateMultistepRule'
import parseStatesToHashMap from 'utils/parseStatesToHashMap'
import renderLaneScoreRule from './Fields/renderLaneScoreRule'
import renderLeadTimeRule from './Fields/renderLeadTimeRule'
import RenderRegionRule from './Fields/RenderRegionRule'
import { StyledText, StyledCol } from './styles'
import Category from './Fields/Category'
import renderMCIRule from './Fields/renderMCIRule'
import renderLTRatioRule from './Fields/renderLTRatioRule'

const getNumberWithoutMileageSuffix = (value) => parseInt(value, 10)
const defaultValues = {
  name: '',
  ruleType: null,
  ruleShipping: null,
  margin: null,
  flat: null,
  guide: null,
  mileageEnd: null,
  mileageInit: null,
  day: null,
  date: [],
  description: '',
  crossRule: false,
  expirationDate: null,
  market: null,
  state: null,
  udScoreInit: null,
  udScoreEnd: null,
  minLeadTime: null,
  maxLeadTime: null,
  states: null,
  udMCIinit: null,
  udMCIend: null,
  udLtRatioInit: null,
  udLtRatioEnd: null,
}
const ControlInput = ({ value, onChange, hasValue, children }) => {
  useEffect(() => {
    hasValue(value)
  }, [hasValue, value])
  return children({ value, onChange })
}

const CreateRuleForm = ({
  variant,
  onCloseModal,
  typesId,
  shippingRule,
  referenceRule,
  columnsParams,
  autocompleteZip,
  formName,
  onChangeLoading,
  clickedOpenCreateMultiStep,
  clickedAddToRulesets,
  setClickedOpenCreateMultiStep,
  setClickedAddToRulesets,
  masterRule = false,
  enableEdit = true,
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { success, error, message, editRule, ruleSelected, resetForm } = useSelector((state) => state.createRule)
  const {
    success: successUpdate,
    error: errorUpdate,
    message: messageUpdate,
  } = useSelector((state) => state.updateRule)
  const [loading, setLoading] = useState(false)
  const [ruleType, setRuleType] = useState('')
  const [ruleShipping, setRuleShipping] = useState('')
  const [dataZipCode, validStatus, zipField, setZip, handleZipChange] = autocompleteZip
  const [dataGeographic, validState, stateField, setPlace, handleStateChange, setValidStatus] =
    useAutocompleteState()
  const { trucktypes } = useSelector((state) => state.getTruckTypeList)
  const [crossRule, setcrossRule] = useState(masterRule)
  const [status, setStatus] = useState('Activated')
  const allowCloseModalRef = useRef(true)
  const [createMultistepVisible, setCreateMultistepVisible] = useState(false)
  const [addToRulesetsVisible, setAddToRulesetsVisible] = useState(false)
  const [margin, setMargin] = useState('')
  const [flat, setFlat] = useState('')
  const [marketState, setMArketState] = useState('')
  const [marketId, setMarketId] = useState('')
  const { data: markets } = useSelector((state) => state.markets)
  const [reEdit, setReEdit] = useState(false)
  const ruleTypeForm = form.getFieldValue('ruleType')
  const [alpha, setAlpha] = useState(true)
  const [assignedStates, setAssignedStates] = useState([])
  const [isPremium, setIsPremium] = useState(false)

  useEffect(() => {
    if (marketState !== '') {
      form.setFieldsValue({ market: '' })
      dispatch(requestGetMarkets(marketState))
    }
  }, [marketState, dispatch, reEdit, form])

  const statesMemo = useMemo(() => ruleSelected?.rule?.states ?? [], [ruleSelected])

  useEffect(() => {
    if (editRule) {
      setStatus(ruleSelected.status)
      if (ruleSelected?.rule?.typeReferenceEquipmentId) {
        setRuleType('truckType')
        form.setFieldsValue({ typeReferenceEquipmentId: ruleSelected?.rule?.typeReferenceEquipmentId })
      }
      if (ruleSelected?.rule?.market && dataGeographic.length > 0 && !reEdit) {
        const statesToSearch = parseStatesToHashMap(dataGeographic)
        form.setFieldsValue({ state: statesToSearch[ruleSelected?.rule?.market?.state] })
      }
      // const states = ruleSelected?.rule?.states
      if (statesMemo && Array.isArray(statesMemo)) {
        setAssignedStates(statesMemo)
        //  form.setFieldsValue({})
      }
    }
  }, [ruleSelected.rule, form, editRule, ruleSelected.status, dispatch, dataGeographic, reEdit, statesMemo])

  const pauseStatus = () => {
    setStatus(EnumStatus.Paused)
  }
  const handleCloseModal = useCallback(() => {
    if (allowCloseModalRef.current) {
      onCloseModal(false)
    }
    allowCloseModalRef.current = true
  }, [onCloseModal])
  useEffect(() => {
    if (success) {
      notify('success', 'Rule has been successfully created')
      handleCloseModal()
    } else if (message && error) {
      notify('error', `Error creating rule: ${message}`)
    } else {
      return
    }
    setLoading(false)
    dispatch(clearData())
  }, [form, error, message, success, dispatch, handleCloseModal])

  useEffect(() => {
    if (successUpdate && editRule) {
      notify('success', 'Rule has been successfully edited')
      onCloseModal(false)
    } else if (errorUpdate) {
      notify('error', `Error updating rule: ${messageUpdate}`)
    } else {
      return
    }
    setLoading(false)
    dispatch(clearUpdateRule())
  }, [errorUpdate, successUpdate, dispatch, form, messageUpdate, editRule, onCloseModal])

  useEffect(() => {
    if (!editRule && resetForm) {
      form.setFieldsValue(defaultValues)
      setRuleType('')
      setRuleShipping('')
      setLoading(false)
    }
  }, [editRule, form, resetForm])

  useLayoutEffect(() => {
    if (loading || !editRule || Object.keys(ruleSelected).length <= 0) {
      return
    }
    const statesToSearch = parseStatesToHashMap(dataGeographic)
    const loadRuleType = {
      [RULE_GEOGRAPHIC]: 'geographicRule',
      [RULE_SIMPLE]: 'simpleRule',
      [RULE_DATE_RANGE]: 'dateRangeRule',
      [RULE_LENGTH]: 'lengthRule',
      [EQUIPMENT_TYPE]: 'truckType',
      [RULE_MARKET]: 'marketRule',
      [RULE_LANE_SCORE]: 'laneScoreRule',
      [RULE_LEAD_TIME]: 'leadTimeRule',
      [RULE_REGION]: 'regionRule',
      [RULE_MCI]: 'mciRule',
      [RULE_LTRATIO]: 'ltRatioRule',
    }[ruleSelected.ruleModel]

    const [reference] =
      Object.entries(referenceRule).find(([, typeShippingId]) => {
        return ruleSelected.rule.typeReferenceGeographicId === typeShippingId
      }) ?? []
    const [typeShipping] =
      Object.entries(shippingRule).find(([, typeShippingId]) => {
        return ruleSelected.rule.typeShippingId === typeShippingId
      }) ?? []
    const simpleAndDateRangeShipping = typeShipping === 'inShipping' ? 'inbound' : 'outbound'

    const shipping = {
      geographicRule: {
        referenceState: simpleAndDateRangeShipping === 'inbound' ? 'inState' : 'outState',
        referenceZip: simpleAndDateRangeShipping === 'inbound' ? 'inZip' : 'outZip',
      }[reference],
      simpleRule: simpleAndDateRangeShipping,
      dateRangeRule: simpleAndDateRangeShipping,
      marketRule: simpleAndDateRangeShipping,
      regionRule: typeShipping === 'inShipping' ? 'inRegion' : 'outRegion',
      laneScoreRule: 'laneScore',
      mciRule: typeShipping === 'inShipping' ? 'inMCI' : 'outMCI',
      ltRatioRule: typeShipping === 'inShipping' ? 'inLT' : 'outLT',
    }[loadRuleType]

    const valueExpirationDate = ruleSelected.expirationDate ? ruleSelected.expirationDate : null
    const validValue = valueExpirationDate !== null && valueExpirationDate !== ''
    const expirationDated = validValue ? moment.utc(ruleSelected.expirationDate) : null
    console.log({ ruleSelected })
    const setFormData = {
      name: ruleSelected.name,
      ruleType: loadRuleType,
      ruleShipping: shipping,
      margin: ruleSelected.rule.margin,
      flat: ruleSelected.rule.flat,
      mileageEnd: ruleSelected.rule.mileageEnd ?? null,
      mileageInit: ruleSelected.rule.mileageInit ?? null,
      day: ruleSelected.rule.day ?? null,
      guide: ruleSelected.rule.guide ?? null,
      expirationDate: expirationDated,
      udScoreInit: ruleSelected.rule.udScoreInit ?? null,
      udScoreEnd: ruleSelected.rule.udScoreEnd ?? null,
      minLeadTime: ruleSelected.rule.minLeadTime ?? null,
      maxLeadTime: ruleSelected.rule.maxLeadTime ?? null,
      udMCIinit: ruleSelected.rule.udMCIinit ?? null,
      udMCIend: ruleSelected.rule.udMCIend ?? null,
      udLtRatioInit: ruleSelected.rule.udLtRatioInit ?? null,
      udLtRatioEnd: ruleSelected.rule.udLtRatioEnd ?? null,
    }

    if (loadRuleType === 'dateRangeRule') {
      setFormData.startDate = moment.utc(ruleSelected.rule.dateInit)
      setFormData.endDate = moment.utc(ruleSelected.rule.dateEnd)
      setFormData.description = ruleSelected.rule.description
    }
    if (loadRuleType === 'marketRule' && !reEdit) {
      setFormData.market = ruleSelected?.rule?.market?.marketAreaName ?? null
      setFormData.state = statesToSearch[ruleSelected?.rule?.market?.state]
      dispatch(requestGetMarkets(ruleSelected?.rule?.market?.state))
      setMarketId(ruleSelected?.rule?.market?._id)
    }
    if (loadRuleType === 'regionRule') {
      setFormData.ruleType = 'geographicRule'
      form.setFieldsValue({ ruleType: 'geographicRule' })
      setRuleType('geographicRule')
    }
    form.setFieldsValue(setFormData)
    if (loadRuleType !== 'regionRule') setRuleType(setFormData.ruleType)

    setRuleShipping(setFormData.ruleShipping)
    setcrossRule(ruleSelected.crossRule ?? false)
    setAlpha(ruleSelected.isAlpha ?? true)
    setIsPremium(ruleSelected.isPremium ?? false)
    setReEdit(true)
  }, [form, editRule, shippingRule, ruleSelected, referenceRule, loading, dataGeographic, dispatch, reEdit])

  useEffect(() => {
    onChangeLoading(loading)
  }, [loading, onChangeLoading])

  const disabledDate = (current) => {
    return current < moment().subtract(2, 'months')
  }

  const submitCreateRule = (data) => {
    if (!createMultistepVisible) {
      setLoading(true)
    } else {
      setLoading(false)
    }
    setValidStatus('')
    const dateEdited = moment(data.expirationDate)
    const expirationDated = dateEdited.isValid() ? data.expirationDate._d : null
    const ruleData = {
      name: data.name,
      expirationDate: expirationDated,
      rule: {
        margin: data.margin,
        flat: data.flat,
      },
    }
    switch (data.ruleType) {
      case 'geographicRule': {
        // This validation was created due to now make
        // both market rule and geographic with the same form
        if (marketId.length > 0) {
          ruleData.typeRuleId = typesId.typeMarket
          ruleData.rule.market = marketId
        } else {
          ruleData.typeRuleId = typesId.typeGeographic
          ruleData.rule.guide = data.guide
        }
        if (ruleShipping === 'outRegion' || ruleShipping === 'inRegion') {
          ruleData.typeRuleId = typesId.typeRegion
          ruleData.rule.states = assignedStates
        }

        if (editRule) ruleData.rule._id = ruleSelected.rule._id
        switch (data.ruleShipping) {
          case 'outState':
            ruleData.rule.typeShippingId = shippingRule.outShipping
            ruleData.rule.typeReferenceGeographicId = referenceRule.referenceState
            break
          case 'outZip':
            ruleData.rule.typeShippingId = shippingRule.outShipping
            ruleData.rule.typeReferenceGeographicId = referenceRule.referenceZip
            break
          case 'inState':
            ruleData.rule.typeShippingId = shippingRule.inShipping
            ruleData.rule.typeReferenceGeographicId = referenceRule.referenceState
            break
          case 'inZip':
            ruleData.rule.typeShippingId = shippingRule.inShipping
            ruleData.rule.typeReferenceGeographicId = referenceRule.referenceZip
            break
          case 'outbound':
            ruleData.rule.typeShippingId = shippingRule.outShipping
            break
          case 'inbound':
            ruleData.rule.typeShippingId = shippingRule.inShipping
            break
          case 'outRegion':
            ruleData.rule.typeShippingId = shippingRule.outShipping
            break
          case 'inRegion':
            ruleData.rule.typeShippingId = shippingRule.inShipping
            break
          default:
            break
        }
        break
      }
      case 'lengthRule': {
        ruleData.typeRuleId = typesId.typeLength
        ruleData.rule.mileageInit = getNumberWithoutMileageSuffix(data.mileageInit)
        ruleData.rule.mileageEnd = getNumberWithoutMileageSuffix(data.mileageEnd)
        break
      }
      case 'laneScoreRule': {
        ruleData.typeRuleId = typesId.typeLaneScore
        ruleData.rule.udScoreInit = data.udScoreInit
        ruleData.rule.udScoreEnd = data.udScoreEnd
        break
      }
      case 'leadTimeRule': {
        ruleData.typeRuleId = typesId.typeLeadTime
        ruleData.rule.minLeadTime = data.minLeadTime
        ruleData.rule.maxLeadTime = data.maxLeadTime
        break
      }
      case 'simpleRule': {
        ruleData.typeRuleId = typesId.typeSimple
        ruleData.rule.day = data.day
        switch (data.ruleShipping) {
          case 'outbound':
            ruleData.rule.typeShippingId = shippingRule.outShipping
            break
          case 'inbound':
            ruleData.rule.typeShippingId = shippingRule.inShipping
            break
          default:
            break
        }
        break
      }
      case 'dateRangeRule': {
        ruleData.typeRuleId = typesId.typeDate
        ruleData.rule.dateInit = data.startDate._d
        ruleData.rule.dateEnd = data.endDate._d
        ruleData.rule.description = data.description
        switch (data.ruleShipping) {
          case 'outbound':
            ruleData.rule.typeShippingId = shippingRule.outShipping
            break
          case 'inbound':
            ruleData.rule.typeShippingId = shippingRule.inShipping
            break
          default:
            break
        }
        break
      }
      case 'truckType': {
        ruleData.typeRuleId = typesId.typeEquipment
        ruleData.rule.typeReferenceEquipmentId = data.typeReferenceEquipmentId
        break
      }
      case 'marketRule': {
        ruleData.typeRuleId = typesId.typeMarket
        ruleData.rule.market = marketId
        switch (data.ruleShipping) {
          case 'outbound':
            ruleData.rule.typeShippingId = shippingRule.outShipping
            break
          case 'inbound':
            ruleData.rule.typeShippingId = shippingRule.inShipping
            break
          default:
            break
        }
        break
      }
      case 'regionRule': {
        switch (data.ruleShipping) {
          case 'outRegion':
            ruleData.rule.typeShippingId = shippingRule.outShipping
            break
          case 'inRegion':
            ruleData.rule.typeShippingId = shippingRule.inShipping
            break
          default:
            break
        }
        break
      }
      case 'mciRule': {
        ruleData.typeRuleId = typesId.typeMci
        ruleData.rule.udMCIinit = data.udMCIinit
        ruleData.rule.udMCIend = data.udMCIend
        switch (data.ruleShipping) {
          case 'outMCI':
            ruleData.rule.typeShippingId = shippingRule.outShipping
            break
          case 'inMCI':
            ruleData.rule.typeShippingId = shippingRule.inShipping
            break
          default:
            break
        }
        break
      }
      case 'ltRatioRule': {
        ruleData.typeRuleId = typesId.typeLtRatio
        ruleData.rule.udLtRatioInit = data.udLtRatioInit
        ruleData.rule.udLtRatioEnd = data.udLtRatioEnd
        switch (data.ruleShipping) {
          case 'outLT':
            ruleData.rule.typeShippingId = shippingRule.outShipping
            break
          case 'inLT':
            ruleData.rule.typeShippingId = shippingRule.inShipping
            break
          default:
            break
        }
        break
      }
      default:
        break
    }
    ruleData.crossRule = crossRule
    ruleData.isAlpha = alpha
    ruleData.isPremium = isPremium

    if (editRule) {
      // eslint-disable-next-line prefer-const
      let previous = { ...ruleSelected }
      if (marketId.length > 0) {
        previous = Object.assign(previous, { rule: { ...previous.rule, market: ruleSelected?.rule?.market?._id } })
      }

      delete previous.__v
      delete previous.createdAt
      delete previous.createdBy
      delete previous.deleted
      delete previous.multiStepsRuleInfo
      delete previous.ruleModel
      delete previous.rulesetsInfo
      delete previous.updatedAt
      previous.typeRuleId = previous.typeRuleId._id
      ruleData.status = status
      const nextData = jsonDiff(previous, ruleData)
      const sizeObject = [...Object.keys(nextData)]
      if (sizeObject.length > 0) {
        nextData.id = ruleSelected._id
        dispatch(requestUpdateRule(nextData))
      } else if (!createMultistepVisible) {
        onCloseModal(false)
      }
    } else {
      dispatch(requestCreateRule([ruleData]))
    }
    if (marketId > 0) {
      setMarketId('')
    }
  }

  const handleValuesChange = useCallback(
    (changedValues) => {
      if (resetForm && !editRule) {
        dispatch(startCreateRule())
      }

      const getDefaultRuleShipping = {
        geographicRule: columnsParams.shipping ?? 'outState',
        simpleRule: columnsParams.shipping ?? 'outbound',
        dateRangeRule: columnsParams.shipping ?? 'outbound',
        marketRule: columnsParams.shipping ?? 'outbound',
        regionRule: columnsParams.shipping ?? 'outRegion',
        laneScoreRule: columnsParams.shipping ?? 'laneScore',
        mciRule: columnsParams.shipping ?? 'laneScore',
        ltRatioRule: columnsParams.shipping ?? 'laneScore',
      }
      const newRuleShipping = changedValues.ruleType
        ? getDefaultRuleShipping[changedValues.ruleType]
        : changedValues.ruleShipping ?? form.getFieldValue('ruleShipping')
      let newRuleType = changedValues.ruleType ?? ruleTypeForm

      if (newRuleType === 'laneScoreRule' || newRuleType === 'mciRule' || newRuleType === 'ltRatioRule') {
        if (newRuleShipping === 'outMCI' || newRuleShipping === 'inMCI') newRuleType = 'mciRule'
        if (newRuleShipping === 'outLT' || newRuleShipping === 'inLT') newRuleType = 'ltRatioRule'
        if (newRuleShipping === 'laneScore') newRuleType = 'laneScoreRule'
      }

      form.setFieldsValue({
        ruleType: newRuleType,
        ruleShipping: newRuleShipping,
      })
      setRuleShipping(newRuleShipping)
      setRuleType(newRuleType)
      setMargin(changedValues.margin ?? form.getFieldValue('margin'))
      setFlat(changedValues.flat ?? form.getFieldValue('flat'))
    },
    [dispatch, form, resetForm, columnsParams.shipping, ruleTypeForm, editRule]
  )

  useEffect(() => {
    const { shipping, type } = columnsParams
    if (ruleTypeForm === undefined) handleValuesChange({ ruleType: type, ruleShipping: shipping })
  }, [columnsParams, handleValuesChange, ruleTypeForm])

  useEffect(() => {
    if (ruleType === 'truckType') {
      dispatch(requestGetTruckTypeList({ active: true }))
    }
  }, [dispatch, ruleType])

  const renderShippingFilter = () => {
    if (ruleType === 'lengthRule' || ruleType === 'truckType' || ruleType === 'leadTimeRule') {
      return null
    }
    if (ruleType === 'laneScoreRule' || ruleType === 'mciRule' || ruleType === 'ltRatioRule') {
      return (
        <Row gutter={10}>
          <Col span={24}>
            <FloatLabel label="Type *" show>
              <DrawerItem name="ruleShipping">
                <Select disabled={ruleType === '' || editRule} size="large">
                  <SelectOption key="laneScore" value="laneScore">
                    UD Score
                  </SelectOption>
                  <SelectOption key="outMCI" value="outMCI">
                    Origin MCI
                  </SelectOption>
                  <SelectOption key="inMCI" value="inMCI">
                    Destination MCI
                  </SelectOption>
                  <SelectOption key="outLT" value="outLT">
                    Origin L/T
                  </SelectOption>
                  <SelectOption key="inLT" value="inLT">
                    Destination L/T
                  </SelectOption>
                </Select>
              </DrawerItem>
            </FloatLabel>
          </Col>
        </Row>
      )
    }

    if (ruleType === 'geographicRule') {
      return (
        <Row gutter={10}>
          <Col span={24}>
            <FloatLabel label="Type *" show>
              <DrawerItem name="ruleShipping">
                <Select disabled={ruleType === '' || editRule} size="large">
                  <SelectOption key="outRegion" value="outRegion">
                    Pickup Region
                  </SelectOption>
                  <SelectOption key="outState" value="outState">
                    Pickup State
                  </SelectOption>
                  <SelectOption key="outbound" value="outbound">
                    Pickup Market
                  </SelectOption>
                  <SelectOption key="outZip" value="outZip">
                    Pickup Zip
                  </SelectOption>
                  <SelectOption key="inRegion" value="inRegion">
                    Delivery Region
                  </SelectOption>
                  <SelectOption key="inState" value="inState">
                    Delivery State
                  </SelectOption>
                  <SelectOption key="inbound" value="inbound">
                    Delivery Market
                  </SelectOption>
                  <SelectOption key="inZip" value="inZip">
                    Delivery Zip
                  </SelectOption>
                </Select>
              </DrawerItem>
            </FloatLabel>
          </Col>
        </Row>
      )
    }
    return (
      <Row gutter={10}>
        <Col span={24}>
          <FloatLabel label="Type *" show>
            <DrawerItem name="ruleShipping">
              <Select disabled={ruleType === '' || editRule} placeholder="Type *" size="large">
                <SelectOption key="outbound" value="outbound">
                  Pick up
                </SelectOption>
                <SelectOption key="inbound" value="inbound">
                  Delivery
                </SelectOption>
              </Select>
            </DrawerItem>
          </FloatLabel>
        </Col>
      </Row>
    )
  }
  const renderTypeEquipment = () => {
    if (ruleType !== 'truckType') {
      return null
    }
    if (ruleType === 'truckType') {
      return (
        <Row gutter={10}>
          <Col span={24}>
            <FloatLabel label="Type *" show>
              <DrawerItem
                name="typeReferenceEquipmentId"
                rules={[{ required: true, message: 'Please enter the Equipment Type' }]}
              >
                <Select disabled={enableEdit ? ruleType === '' : true} size="large">
                  {trucktypes.map((item, index) => (
                    <SelectOption value={`${item._id}`} key={index}>
                      {item.name}
                    </SelectOption>
                  ))}
                </Select>
              </DrawerItem>
            </FloatLabel>
          </Col>
        </Row>
      )
    }
    return (
      <Row gutter={10}>
        <Col span={24}>
          <FloatLabel label="Type *" show>
            <DrawerItem name="ruleShipping">
              <Select disabled={ruleType === ''} placeholder="Type *" size="large">
                <SelectOption key="outbound" value="outbound">
                  Pick up
                </SelectOption>
                <SelectOption key="inbound" value="inbound">
                  Delivery
                </SelectOption>
              </Select>
            </DrawerItem>
          </FloatLabel>
        </Col>
      </Row>
    )
  }
  const renderGeographicRule = () => {
    const zipInput = () => (
      <Row gutter={10}>
        <Col span={24}>
          <InputWithLabel label="Zip *">
            {(hasValue) => (
              <DrawerItem
                name="guide"
                rules={[{ required: true, message: 'Please select a 3 Digit zip' }]}
                hasFeedback
                validateStatus={zipField === 0 && validStatus}
              >
                <ControlInput hasValue={hasValue}>
                  {({ value, onChange }) => (
                    <Select
                      showSearch
                      size="large"
                      defaultActiveFirstOption={false}
                      placeholder="Zip"
                      notFoundContent={null}
                      onSearch={setZip}
                      onChange={(...props) => {
                        handleZipChange(props[0], 0)
                        onChange(...props)
                      }}
                      options={dataZipCode}
                      value={value}
                    />
                  )}
                </ControlInput>
              </DrawerItem>
            )}
          </InputWithLabel>
        </Col>
      </Row>
    )
    const stateInput = ({ stateFieldToValidate }) => (
      <Row gutter={10}>
        <Col span={24}>
          <InputWithLabel label="State *">
            {(hasValue) => (
              <DrawerItem
                name="guide"
                rules={[{ required: true, message: 'Please select the state' }]}
                hasFeedback
                validateStatus={stateField === stateFieldToValidate && validState}
              >
                <ControlInput hasValue={hasValue}>
                  {({ value, onChange }) => (
                    <Select
                      disabled={!enableEdit}
                      showSearch
                      size="large"
                      options={dataGeographic}
                      defaultActiveFirstOption={false}
                      notFoundContent={null}
                      onSearch={setPlace}
                      value={value}
                      onChange={(...props) => {
                        // here
                        handleStateChange(props[0])
                        onChange(...props)
                      }}
                    />
                  )}
                </ControlInput>
              </DrawerItem>
            )}
          </InputWithLabel>
        </Col>
      </Row>
    )
    return {
      outState: stateInput({ stateFieldToValidate: 0 }),
      outZip: zipInput(),
      inState: stateInput({ stateFieldToValidate: 0 }),
      inZip: zipInput(),
    }[ruleShipping]
  }
  const renderLengthRule = () => {
    return (
      <Row gutter={10}>
        <Col span={24} style={{ marginBottom: 10 }}>
          <StyledText>If mileage is between</StyledText>
        </Col>
        <Col span={12}>
          <DrawerItem
            name="mileageInit"
            rules={[
              {
                required: true,
                message: 'Please enter a number',
              },
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  const mileageStart = getNumberWithoutMileageSuffix(value)
                  const mileageEnd = getNumberWithoutMileageSuffix(getFieldValue('mileageEnd') || '0')
                  if (mileageStart >= mileageEnd) {
                    throw new Error('Mileage start should be less than mileage end')
                  }
                },
              }),
            ]}
            dependencies={['mileageEnd']}
          >
            <NumberFormat
              suffix=" mi"
              displayType="input"
              type="text"
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              customInput={(props) => <InputGeneric disabled={!enableEdit} size="large" {...props} />}
              allowNegative={false}
            />
          </DrawerItem>
        </Col>
        <Col span={12}>
          <DrawerItem
            name="mileageEnd"
            rules={[
              {
                required: true,
                message: 'Please enter a number',
              },
            ]}
          >
            <NumberFormat
              suffix=" mi"
              displayType="input"
              type="text"
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              customInput={(props) => <InputGeneric disabled={!enableEdit} size="large" {...props} />}
              allowNegative={false}
            />
          </DrawerItem>
        </Col>
      </Row>
    )
  }

  const newDatePicker = (value, onChange) => (
    <DatePicker
      disabled={!enableEdit}
      style={{ width: '100%' }}
      placeholder=""
      disabledDate={disabledDate}
      size="large"
      suffixIcon={null}
      value={value}
      onChange={onChange}
    />
  )
  const renderDescription = () => {
    return (
      <Row gutter={10}>
        <Col span={24}>
          <InputWithLabel label="Description">
            {(hasValue) => (
              <DrawerItem name="description" rules={descriptionRules}>
                <ControlInput hasValue={hasValue}>
                  {({ value, onChange }) => (
                    <Input.TextArea
                      disabled={enableEdit ? ruleType === '' : true}
                      style={{ width: '100%', fontSize: 16 }}
                      rows={3}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                </ControlInput>
              </DrawerItem>
            )}
          </InputWithLabel>
        </Col>
      </Row>
    )
  }
  const renderDates = () => {
    return (
      <Row gutter={10}>
        <Col span={12}>
          <InputWithLabel label="Start date*">
            {(hasValue) => (
              <DrawerItem name="startDate" rules={[{ required: true, message: 'Please select a start date' }]}>
                <ControlInput hasValue={hasValue}>
                  {({ value, onChange }) => newDatePicker(value, onChange)}
                </ControlInput>
              </DrawerItem>
            )}
          </InputWithLabel>
        </Col>
        <Col span={12}>
          <InputWithLabel label="End date*">
            {(hasValue) => (
              <DrawerItem name="endDate" rules={[{ required: true, message: 'Please select a end date' }]}>
                <ControlInput hasValue={hasValue}>
                  {({ value, onChange }) => newDatePicker(value, onChange)}
                </ControlInput>
              </DrawerItem>
            )}
          </InputWithLabel>
        </Col>
      </Row>
    )
  }
  const renderStateMarket = () => {
    const stateInput = ({ stateFieldToValidate }) => (
      <Row gutter={10}>
        <Col span={24}>
          <InputWithLabel label="State *">
            {(hasValue) => (
              <DrawerItem
                name="state"
                hasFeedback
                validateStatus={stateField === stateFieldToValidate && validState}
              >
                <ControlInput hasValue={hasValue}>
                  {({ value, onChange }) => (
                    <Select
                      disabled={!enableEdit}
                      showSearch
                      size="large"
                      options={dataGeographic}
                      defaultActiveFirstOption={false}
                      notFoundContent={null}
                      onSearch={setPlace}
                      value={value}
                      onChange={(...props) => {
                        dispatch(clearGetMarkets())
                        setMArketState(handleMarketState(props))
                        handleStateChange(props[0])
                        onChange(...props)
                      }}
                    />
                  )}
                </ControlInput>
              </DrawerItem>
            )}
          </InputWithLabel>
        </Col>
        <Col span={24}>
          <InputWithLabel label="Market *">
            {(hasValue) => (
              <DrawerItem name="market" rules={[{ required: true, message: 'Please select one option' }]}>
                <ControlInput hasValue={hasValue}>
                  {({ value, onChange }) => (
                    <Select
                      showSearch
                      size="large"
                      options={markets ?? []}
                      defaultActiveFirstOption={false}
                      notFoundContent={null}
                      value={value}
                      onChange={(...props) => {
                        setMarketId(props[1].uid)
                        onChange(...props)
                      }}
                    />
                  )}
                </ControlInput>
              </DrawerItem>
            )}
          </InputWithLabel>
        </Col>
      </Row>
    )
    return stateInput({ stateFieldToValidate: 0 })
  }
  const openCreateMultistep = useCallback(async () => {
    await form.validateFields()
    allowCloseModalRef.current = false
    await form.submit()
    setCreateMultistepVisible(true)
  }, [form])
  const openAddToRulesets = useCallback(async () => {
    await form.validateFields()
    setAddToRulesetsVisible(true)
  }, [form])
  useEffect(() => {
    if (clickedOpenCreateMultiStep) {
      openCreateMultistep().then()
      setClickedOpenCreateMultiStep(false)
    }
  }, [clickedOpenCreateMultiStep, openCreateMultistep, setClickedOpenCreateMultiStep])
  useEffect(() => {
    if (clickedAddToRulesets) {
      openAddToRulesets().then()
      setClickedAddToRulesets(false)
    }
  }, [clickedAddToRulesets, openAddToRulesets, setClickedAddToRulesets])
  const changedDefaultValues = useMemo(
    () => ({
      margin,
      flat,
    }),
    [margin, flat]
  )
  return (
    <>
      <Row justify="space-between">
        <Col>
          <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px', marginBottom: 16 }}>
            {editRule ? 'Edit the information of this Rule' : 'Add the information of this new rule'}
          </Text>
        </Col>
        <Col>
          {editRule && (
            <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <FiPauseCircle
                onClick={enableEdit ? status !== EnumStatus.Paused && pauseStatus : () => ''}
                style={
                  enableEdit && status !== EnumStatus.Paused
                    ? { fontSize: '19px', color: colors.dark_blue.default, cursor: 'pointer' }
                    : { fontSize: '19px', color: colors.light_grey.default, cursor: 'not-allowed' }
                }
              />
              <Text
                underline
                disabled={enableEdit ? status === EnumStatus.Paused : enableEdit}
                style={
                  enableEdit && status !== EnumStatus.Paused
                    ? {
                        marginLeft: '7px',
                        color: '#002555',
                        fontWeight: 400,
                        fontSize: '16px',
                        cursor: 'pointer',
                      }
                    : {
                        marginLeft: '7px',
                        fontWeight: 400,
                        fontSize: '16px',
                      }
                }
                onClick={enableEdit ? status !== EnumStatus.Paused && pauseStatus : () => ''}
              >
                Pause Rule
              </Text>
            </Row>
          )}
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        name={formName}
        initialValues={defaultValues}
        onFinish={submitCreateRule}
        onValuesChange={handleValuesChange}
        style={{ marginTop: 16 }}
        hideRequiredMark
      >
        <Row gutter={10}>
          <Col span={24}>
            <FloatLabel label="Category *" show>
              <Category editRule={editRule} />
            </FloatLabel>
          </Col>
        </Row>
        {renderShippingFilter()}
        {renderTypeEquipment()}
        <Row gutter={10}>
          <Col span={24}>
            <InputWithLabel label="Name *">
              {(hasValue) => (
                <DrawerItem name="name" rules={stringRules('Rule´s name')}>
                  <ControlInput hasValue={hasValue}>
                    {({ value, onChange }) => (
                      <InputGeneric
                        disabled={ruleType === '' || !enableEdit}
                        size="large"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  </ControlInput>
                </DrawerItem>
              )}
            </InputWithLabel>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <InputWithLabel label="End date">
              {(hasValue) => (
                <DrawerItem name="expirationDate">
                  <ControlInput hasValue={hasValue}>
                    {({ value, onChange }) => newDatePicker(value, onChange)}
                  </ControlInput>
                </DrawerItem>
              )}
            </InputWithLabel>
          </Col>
        </Row>
        {ruleType === 'geographicRule' ? renderGeographicRule() : null}
        {ruleType === 'lengthRule' ? renderLengthRule() : null}
        {ruleType === 'laneScoreRule' ? renderLaneScoreRule(enableEdit) : null}
        {ruleType === 'mciRule' ? renderMCIRule(enableEdit) : null}
        {ruleType === 'ltRatioRule' ? renderLTRatioRule(enableEdit) : null}

        {ruleType === 'leadTimeRule' ? renderLeadTimeRule(enableEdit) : null}

        {ruleType === 'simpleRule' && (
          <Row gutter={10}>
            <Col span={24}>
              <InputWithLabel label="Day *">
                {(hasValue) => (
                  <DrawerItem name="day" rules={[{ required: true, message: 'Please select a day' }]}>
                    <ControlInput hasValue={hasValue}>
                      {({ value, onChange }) => (
                        <Select disabled={!enableEdit} size="large" value={value} onChange={onChange}>
                          {days.map((entry) => {
                            return (
                              <Select.Option key={entry} value={entry}>
                                {entry}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      )}
                    </ControlInput>
                  </DrawerItem>
                )}
              </InputWithLabel>
            </Col>
          </Row>
        )}
        {ruleType === 'dateRangeRule' ? renderDescription() : null}
        {ruleType === 'dateRangeRule' ? renderDates() : null}
        {ruleType === 'geographicRule' && (ruleShipping === 'outbound' || ruleShipping === 'inbound')
          ? renderStateMarket()
          : null}
        {ruleType === 'geographicRule' && (ruleShipping === 'outRegion' || ruleShipping === 'inRegion') ? (
          <RenderRegionRule
            handleValues={setAssignedStates}
            states={dataGeographic ?? []}
            resetFormValue={() => form.setFieldsValue({ singleState: '' })}
            loadCustomers={ruleSelected?.rule?.states ?? []}
          />
        ) : null}
        <Row gutter={10}>
          <StyledCol span={24}>
            <Text
              style={{
                color: '#545454',
                fontWeight: 400,
                fontSize: '16px',
                fontStyle: 'italic',
                marginTop: -10,
                marginBottom: 10,
              }}
            >
              Make these rate adjustments
            </Text>
          </StyledCol>
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <NumberInput
              disabled={!enableEdit}
              name="margin"
              label="Percentage *"
              suffix="%"
              rules={[
                { required: true, message: 'Please enter a margin' },
                {
                  type: 'number',
                  max: 100,
                  message: 'The margin should not be greater than 100',
                  transform: Number,
                },
                {
                  type: 'number',
                  min: -100,
                  message: 'The margin should not be less than -100',
                  transform: Number,
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <NumberInput
              disabled={!enableEdit}
              name="flat"
              label="Flat *"
              prefix="$"
              rules={[{ required: true, message: 'Please enter a flat' }]}
              labelStyle={{ leftBeforeFloat: '24px' }}
            />
          </Col>
        </Row>
      </Form>
      <Row gutter={10} style={{ margin: '7px 0px' }}>
        <Col span={12}>
          <Checkbox
            disabled={!enableEdit}
            checked={crossRule}
            onChange={() => {
              setcrossRule(!crossRule)
            }}
          >
            <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>Master Rule</Text>
          </Checkbox>
        </Col>
        <Col span={12}>
          {crossRule ? (
            <Checkbox
              disabled={!enableEdit}
              checked={alpha}
              onChange={() => {
                setAlpha((prev) => !prev)
              }}
            >
              <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>Alpha Rule</Text>
            </Checkbox>
          ) : (
            ''
          )}
        </Col>
      </Row>
      <Row gutter={10} style={{ margin: '7px 0px' }}>
        <Col span={12}>
          {crossRule ? (
            <Checkbox
              disabled={!enableEdit}
              checked={isPremium}
              onChange={() => {
                setIsPremium((prev) => !prev)
              }}
            >
              <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>Premium Rule</Text>
            </Checkbox>
          ) : (
            ''
          )}
        </Col>
      </Row>

      {variant !== 'addForMultiStep' && (
        <CreateMultistepRuleModal
          enableEdit={enableEdit}
          visible={createMultistepVisible}
          onCloseModalBySuccessfulCreation={() => {
            setCreateMultistepVisible(false)
            onCloseModal(false)
          }}
          onCloseModal={setCreateMultistepVisible}
          changedDefaultValues={changedDefaultValues}
        />
      )}
      <AddToRulesetsModal
        visible={addToRulesetsVisible}
        onClose={() => setAddToRulesetsVisible(false)}
        onTriggerSubmitForm={() => form.submit()}
      />
    </>
  )
}

export default CreateRuleForm
