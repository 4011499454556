import React, { useState, useEffect, useRef, useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearCreateSuggestion, requestCreateSuggestion } from 'store/duck/createSuggestion.duck'
import { requestEditSuggestion } from 'store/duck/editSuggestion.duck'
import Modal from 'components/Modal'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Col from 'antd/lib/col'
import PrimarySubmit from 'components/PrimarySubmit'
import './animated-send.css'
import notify from 'utils/notify'



const ModalFeedback = React.memo(({ visible, close, SuggestionId }) => {
  const [form] = Form.useForm()
  const [suggestion, setSuggestion] = useState('');
  const dispatch = useDispatch()

  const { create, edit } = useSelector((state) => ({
    create: state.createSuggestion, 
    edit: state.editSuggestion
  }))
  const suggData = useRef(suggestion)
  const onFormSubmit = useCallback(() => {
    const suggestionData = {suggestion}
    if (SuggestionId != null) {
      dispatch(requestEditSuggestion({suggestion: suggestionData.suggestion}, SuggestionId))
      return
    }
    if(SuggestionId == null){
      dispatch(requestCreateSuggestion(suggestionData));
    }
  },[SuggestionId, suggestion, dispatch])

  useEffect(() => {
    if(visible){
      if (create.success || create.error || edit.success || edit.error) {
        if ((create.success && suggData !== '')||( edit.success && suggData !== '')) {
            notify('success', `Feedback recieved!`)
        }
        if(edit.error){
          notify('error', `Error editing feedback! ${edit.messageEdit}`)
        }
        if (create.error) {
          notify('error', `Error sending feedback! ${create.message}`)
        }
        dispatch(clearCreateSuggestion())
        setSuggestion('')
        close(false)
      }
    }
  }, [dispatch, create, close, edit])
 

    /*
     <div className="letterImage">
        <div className="animatedMail">
          <div className="backFold">‎</div>
          <div className="topFold">‎ </div>
          <div className="body">‎ </div>
          <div className="leftFold">‎ </div>
        </div>
      </div>
    */
  return (
    <>     
      <Modal
        centered={false}
        style={{ top: 190 , 'z-index': 1}}
        title="Feedback"
        visible={visible}
        onCancel={() => close(false)}
        width={880}
        height='300px'
      >
        <Row>
          <Form form={form} layout="vertical" requiredMark={false}>
            <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Col span={24} style={{ marginTop: '15px' }}>
                  <Row span={24}>
                    <textarea 
                      value={suggestion}
                      onChange={(e) => setSuggestion(e.target.value)}
                      placeholder= "Enter your suggestions here..." 
                      className="min-h-[150px] p-2 border rounded"
                      style={{ width: '770px', height: '150px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}
                    />
                  </Row>
                  <Row span={24}justify="center">
                    <div style={{ textAlign: 'center', bottom: '5px', marginTop: '20px' , width: '100%' }}>
                      <PrimarySubmit label="Submit Feedback" htmlType="submit" onClick={onFormSubmit}/>
                    </div>
                  </Row>
                </Col>
            </Col>
          </Form>
        </Row>
      </Modal>
    </>
  )
})

export default ModalFeedback