import React, { useEffect, useState, useMemo } from 'react'
import TitleDrawer from 'components/TitleDrawer'
import PrimarySubmit from 'components/PrimarySubmit'
import TextInput from 'components/Form/TextInput'
import { StyledModal } from 'containers/rules/ListMultiStep/utils/CreateMultistepRule/styles'
import { useDispatch, useSelector } from 'react-redux'
import CustomerPhoto from 'assets/img/avatar.png'
import Form from 'antd/lib/form'
import { Col, Row } from 'antd'
import Text from 'antd/lib/typography/Text'
import { AvatarContainer } from 'containers/users/UserProfileInfo/styles'
import Upload from 'antd/lib/upload'
import Badge from 'antd/lib/badge'
import { MdPhotoCamera } from 'react-icons/md'
import InputTime from 'components/Form/InputTime'
import colors from 'providers/theme/config/colorPallete'
import Avatar from 'antd/lib/avatar'
import CryptoJS from 'crypto-js'
import {
  addressRulesNoRequired,
  alphanumericText,
  emailRules,
  phoneRulesNoRequired,
  stringRules,
  passwordNoRequired,
  accountManagerRules,
} from 'utils/inputRules'
import { InputGeneric } from 'components/Input'
import Checkbox from 'antd/lib/checkbox'
import { requestCreateCustomer, resetCreateCustomer } from 'store/duck/createCustomer.duck'
import { requestEditCustomer, resetEditCustomer } from 'store/duck/editCustomer.duck'
import { requestGetCustomer, resetGetCustomer } from 'store/duck/getCustomer.duck'
import DrawerItem from 'components/Form/DrawerItem'
import InputWithLabel from 'components/Form/InputWithLabel'
import notify from 'utils/notify'
import decrypt from 'utils/decrypt'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import jsonDiff from 'utils/jsonDiff'
import { isNone } from 'utils/filters'
import { InputPassword } from './styles'
import FuelPrices from './FuelPrices'

const formName = 'create-customer-form'

const CreateForm = ({ handleOnClose, visible, trucks = [] }) => {
  const [customerInfo] = Form.useForm()
  const dispatch = useDispatch()
  const [avatar, setAvatar] = useState(CustomerPhoto)
  const { editCustomer, customerSelected } = useSelector((state) => state.createCustomer)
  const {
    success: successEditCustomer,
    error: errorEditCustomer,
    message: errorMessageEditCustomer,
  } = useSelector((state) => state.editCustomer)
  const { success, error, message } = useSelector((state) => state.createCustomer)
  const {
    data: getCustomer,
    success: getCustomerSuccess,
    error: getCustomerError,
    message: getCustomerMessage,
  } = useSelector((state) => state.getCustomer)
  const [customerAvatar, setCustomerAvatar] = useState('')
  const [, setRulesets] = useState([])
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [defaultFuel, setdefaultFuel] = useState(false)
  const [none, setnone] = useState(true)
  const [memoPassword, setMemoPassword] = useState('')
  const [alpha, setAlpha] = useState(true)
  const [user, setUser] = useState(false)

  const getCustomerData = useMemo(() => getCustomer, [getCustomer])


  const handlePreview = (file) => {
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => {
        setAvatar(reader.result)
      },
      false
    )
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const beforeUpload = (file) => {
    if (file.size > 300000) {
      notify('error', 'Image must be smaller than 300KB!')
      setCustomerAvatar('')
      setAvatar(CustomerPhoto)
      return false
    }
    handlePreview(file)
    setCustomerAvatar(file)
    return false
  }
  const onFormSubmit = () => {
    const formData = new FormData()
    const dataForm = {}
    const newCustomerInfo = customerInfo.getFieldsValue()
    const password = newCustomerInfo?.apiPassword

    Object.keys(newCustomerInfo).forEach((key) =>
      newCustomerInfo[key] === undefined ? delete newCustomerInfo[key] : {}
    )
    for (const property in newCustomerInfo) {
      if (
        property !== 'margin' ||
        property !== 'flat' ||
        property !== 'vanFuelPrice' ||
        property !== 'reeferFuelPrice' ||
        property !== 'flatbedFuelPrice'
      ) {
        dataForm[property] = newCustomerInfo[property]
      }
    }
    if (password !== '') {
      if (memoPassword !== password) {
        const pass = CryptoJS.AES.encrypt(password, process.env.REACT_APP_CRYPTO_KEY).toString()
        dataForm.apiPassword = pass
      }
    }

    if (customerAvatar) formData.set('avatar', customerAvatar)
    if (newCustomerInfo.flat !== '') dataForm.flat = Number(newCustomerInfo.flat)
    if (newCustomerInfo.margin !== '') dataForm.margin = Number(newCustomerInfo.margin)
    dataForm.considerCustomFuelPrice = checked

    if (checked) {
      dataForm.vanFuelPrice = Number(newCustomerInfo.vanFuelPrice)
      dataForm.flatbedFuelPrice = Number(newCustomerInfo.flatbedFuelPrice)
      dataForm.reeferFuelPrice = Number(newCustomerInfo.reeferFuelPrice)
    }

    dataForm.isAlpha = alpha
    dataForm.defaultFuel = defaultFuel
    dataForm.customPrice = none
    

    setLoading(true)
    if (editCustomer) {
      if (defaultFuel) {
        dataForm.vanFuelPrice = Number(getCustomerData?.customer?.vanFuelPrice) ?? null
        dataForm.flatbedFuelPrice = Number(getCustomerData?.customer?.flatbedFuelPrice) ?? null
        dataForm.reeferFuelPrice = Number(getCustomerData?.customer?.reeferFuelPrice) ?? null
      }


      if (memoPassword === password) {
        dataForm.apiPassword = getCustomerData.customer.apiPassword ?? ''
      }

      const previous = { ...getCustomerData.customer }
      delete previous._id
      delete dataForm.idnumber
      const nextData = jsonDiff(previous, dataForm)

      dispatch(requestEditCustomer(nextData, customerSelected._id))
    } else {
      dataForm.user = user.toString()
      dispatch(requestCreateCustomer(dataForm))
    }
  }

  useEffect(() => {
    if (success) {
      notify('success', 'Customer created successfully')
      setLoading(false)
      setAvatar(CustomerPhoto)
      setCustomerAvatar('')
      handleOnClose(false)
      customerInfo.resetFields()
      dispatch(resetCreateCustomer())
    }
    if (error) {
      if (message) {
        const emailExists = message.match(/email already exists/g)
        if (emailExists) {
          notify('error', 'Error message: This email has already been used, try another one.')
        } else {
          notify('error', `Create Customer Error!. ${message}`)
        }
      } else {
        notify('error', 'Error message: Customer error unknowed.')
      }

      setLoading(false)
      dispatch(resetCreateCustomer())
    }
  }, [error, message, success, dispatch, customerInfo, handleOnClose])

  useEffect(() => {
    if (customerSelected._id && !getCustomerSuccess && !getCustomerError && visible) {
      setLoading(true)
      dispatch(requestGetCustomer(customerSelected._id))
    }
  }, [customerSelected._id, dispatch, getCustomerError, getCustomerSuccess, visible])

  useEffect(() => {
    if (editCustomer) {
      if (getCustomerSuccess) {
        setLoading(false)
        if (getCustomerData.customer.avatar) {
          setAvatar(getCustomerData.customer.avatar)
        }
        const Password = getCustomerData.customer.apiPassword ?? ''
        const passDecript = Password !== '' ? decrypt(Password) : ''
        setMemoPassword(passDecript)
        customerInfo.setFieldsValue({
          idnumber: getCustomerData.customer._id,
          name: getCustomerData.customer.name,
          email: getCustomerData.customer.email,
          address: getCustomerData.customer.address,
          phone: getCustomerData.customer.phone,
          tmsId: getCustomerData.customer.tmsId,
          poc: getCustomerData.customer.poc,
          margin: getCustomerData.customer.margin,
          flat: getCustomerData.customer.flat,
          customFuelPrice: getCustomerData.customer.customFuelPrice ?? 0,
          apiPassword: passDecript,
          vanFuelPrice: getCustomerData.customer.vanFuelPrice,
          flatbedFuelPrice: getCustomerData.customer.flatbedFuelPrice,
          reeferFuelPrice: getCustomerData.customer.reeferFuelPrice,
          accountManager: getCustomerData.customer.accountManager,
          leadTime: getCustomerData.customer?.leadTime ?? '',
          maximumMarkdown: getCustomerData.customer?.maximumMarkdown ?? null,
        })
        setRulesets(getCustomerData.ruleSets.filter((ruleset) => ruleset.active))
        setChecked(getCustomerData?.customer?.considerCustomFuelPrice ?? false)
        setdefaultFuel(getCustomerData?.customer?.defaultFuel ?? false)
        const getNoneValue = isNone(getCustomerData?.customer)
        setnone(getNoneValue)
        setAlpha(getCustomerData?.customer?.isAlpha ?? true)

      }
    }
  }, [getCustomerData, customerInfo, getCustomerSuccess, editCustomer])

  useEffect(() => {
    if (getCustomerError) {
      notify('error', 'Get user Data error!')
    }
  }, [getCustomerError, getCustomerMessage])

  useEffect(() => {
    if (successEditCustomer || errorEditCustomer) {
      if (successEditCustomer) {
        notify('success', 'Customer edited successfully')
        setAvatar(CustomerPhoto)
        setCustomerAvatar('')
        customerInfo.resetFields()
        handleOnClose(false)
        dispatch(resetEditCustomer())
        dispatch(resetGetCustomer())
      }
      if (errorEditCustomer) {
        notify('error', 'Customer edited Error!')
        dispatch(resetEditCustomer())
        dispatch(resetGetCustomer())
        setLoading(false)
      }
    }
  }, [
    dispatch,
    editCustomer,
    customerInfo,
    handleOnClose,
    successEditCustomer,
    errorEditCustomer,
    errorMessageEditCustomer,
  ])

  const ControlInput = ({ value, onChange, hasValue, children }) => {
    useEffect(() => {
      hasValue(value)
    }, [hasValue, value])

    return children({ value, onChange })
  }

  useEffect(() => {
    if (defaultFuel && trucks.length > 0) {
      trucks.forEach((item) => {
        if (item.name === "Van" && item.defaultFuel) {
          customerInfo.setFieldsValue({ vanFuelPrice: item.defaultFuel })
        }
        if (item.name === "Flatbed" && item.defaultFuel) {
          customerInfo.setFieldsValue({ flatbedFuelPrice: item.defaultFuel })
        }
        if (item.name === "Reefer" && item.defaultFuel) {
          customerInfo.setFieldsValue({ reeferFuelPrice: item.defaultFuel })
        }
      })
    }
  }, [defaultFuel, trucks, customerInfo])

  useEffect(() => {
    if (editCustomer && !defaultFuel) {

      const vanFuelPrice = getCustomerData?.customer?.vanFuelPrice ?? null
      const flatbedFuelPrice = getCustomerData?.customer?.flatbedFuelPrice ?? null
      const reeferFuelPrice = getCustomerData?.customer?.reeferFuelPrice ?? null

      customerInfo.setFieldsValue({ vanFuelPrice })
      customerInfo.setFieldsValue({ flatbedFuelPrice })
      customerInfo.setFieldsValue({ reeferFuelPrice })
    }
  }, [editCustomer, defaultFuel, customerInfo, getCustomerData])





  return (
    <>
      <Row justify="space-between" style={{ marginBottom: '14px' }}>
        <Col>
          <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px', marginBottom: '16' }}>
            {editCustomer
              ? 'Edit the information of this new Customer'
              : 'Add the information of this new Customer'}
          </Text>
        </Col>
      </Row>
      <Form
        form={customerInfo}
        layout="vertical"
        name={formName}
        onFinish={onFormSubmit}
        style={{ marginTop: 6 }}
        autoComplete="off"
        hideRequiredMark
      >
        <Row justify="center">
          <AvatarContainer>
            <Upload accept=".jpg,.jpeg,.png" fileList={[]} name="file" beforeUpload={beforeUpload}>
              <Badge
                offset={[0, 20]}
                count={
                  <MdPhotoCamera
                    style={{
                      color: 'white',
                      fontSize: '31px',
                      padding: '4px',
                      borderRadius: '50%',
                      backgroundColor: colors.dark_blue.default,
                    }}
                  />
                }
              >
                <Avatar size={70} src={avatar} />
              </Badge>
            </Upload>
          </AvatarContainer>
        </Row>
        <Row gutter={[26, 10]}>
          {editCustomer && (
            <Col span={24}>
              <TextInput label="ID number" name="idnumber" disabled />
            </Col>
          )}
          <Col span={24}>
            <TextInput label="Full Name*" name="name" rules={stringRules('customer name')} />
          </Col>
          <Col span={24}>
            <TextInput data-testid="email-testid" label="Email*" name="email" rules={emailRules} />
          </Col>
          <Col span={24}>
            <TextInput label="Phone" name="phone" rules={phoneRulesNoRequired} />
          </Col>
          <Col span={24}>
            <TextInput label="Address" name="address" rules={addressRulesNoRequired} />
          </Col>
          <Col span={24}>
            <TextInput
              label="Account Manager"
              name="accountManager"
              rules={accountManagerRules('customer name')}
            />
          </Col>
          <Col span={24}>
            <TextInput
              autoComplete="new-password"
              label="Password"
              name="apiPassword"
              rules={passwordNoRequired}
              type="password"
              Input={InputPassword}
              iconRender={(isVisible) => (isVisible ? <AiFillEye /> : <AiFillEyeInvisible />)}
            />
          </Col>
          <Col span={12}>
            <InputTime label="Lead Time" name="leadTime" />
          </Col>

          <Col span={12}>
            <InputWithLabel label="Maximum Markdown *">
              {(hasValue) => (
                <DrawerItem
                  name="maximumMarkdown"
                  rules={[
                    {
                      type: 'number',
                      max: 100,
                      message: 'The Maximum Markdown should not be greater than 100',
                      transform: Number,
                    },
                    {
                      type: 'number',
                      min: -100,
                      message: 'The Maximum Markdown should not be less than -100',
                      transform: Number,
                    },
                  ]}
                  style={{ marginBottom: 10 }}
                  shouldUpdate
                >
                  <ControlInput hasValue={hasValue}>
                    {({ value, onChange }) => (
                      <InputGeneric type="number" size="large" value={value} onChange={onChange} />
                    )}
                  </ControlInput>
                </DrawerItem>
              )}
            </InputWithLabel>
          </Col>

          <Col span={12}>
            <TextInput name="tmsId" label="TMS" rules={alphanumericText} />
          </Col>
          <Col span={12}>
            <TextInput name="poc" label="POC Name" rules={alphanumericText} />
          </Col>
          <Col span={12}>
            <InputWithLabel label="Percentage *">
              {(hasValue) => (
                <DrawerItem
                  name="margin"
                  rules={[
                    { required: true, message: 'Please enter a margin' },
                    {
                      type: 'number',
                      max: 100,
                      message: 'The margin should not be greater than 100',
                      transform: Number,
                    },
                    {
                      type: 'number',
                      min: -100,
                      message: 'The margin should not be less than -100',
                      transform: Number,
                    },
                  ]}
                  style={{ marginBottom: 10 }}
                  shouldUpdate
                >
                  <ControlInput hasValue={hasValue}>
                    {({ value, onChange }) => (
                      <InputGeneric type="number" size="large" suffix="%" value={value} onChange={onChange} />
                    )}
                  </ControlInput>
                </DrawerItem>
              )}
            </InputWithLabel>
          </Col>
          <Col span={12}>
            <InputWithLabel label="Flat *" labelStyle={{ leftBeforeFloat: '24px' }}>
              {(hasValue) => (
                <DrawerItem
                  name="flat"
                  rules={[
                    { required: true, message: 'Please enter a flat' },
                    // {
                    //   type: 'number',
                    //   min: 0,
                    //   message: 'The flat should not be less than 0',
                    //   transform: Number,
                    // },
                  ]}
                  style={{ marginBottom: 10 }}
                  shouldUpdate
                >
                  <ControlInput hasValue={hasValue}>
                    {({ value, onChange }) => (
                      <InputGeneric
                        data-testid="flat-testid"
                        type="number"
                        size="large"
                        prefix="$"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  </ControlInput>
                </DrawerItem>
              )}
            </InputWithLabel>
          </Col>
          <Col span={12}>
            <Checkbox
              checked={alpha}
              onChange={() => {
                setAlpha((prev) => !prev)
              }}
            >
              <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>Alpha rules </Text>
            </Checkbox>
          </Col>
          <Col span={12}>
            <Checkbox
              checked={user}
              onChange={() => {
                setUser((prev) => !prev)
              }}
            >
              <Text style={{ color: '#545454', fontWeight: 400, fontSize: '16px' }}>Make User </Text>
            </Checkbox>
          </Col>
          <Col span={24}>
            <div
              style={{
                width: '100%',
                height: 1,
                backgroundColor: colors.light_grey.default,
                marginBottom: 10,
                marginTop: 0,
              }}
            />
          </Col>
        </Row>
        <FuelPrices
          setDefault={setdefaultFuel}
          setCustom={setChecked}
          setNoPrice={setnone}
          DefaultValue={defaultFuel}
          CustomValue={checked}
          NoneValue={none}
          form={customerInfo}
        />

        <Row gutter={[26, 8]} style={{ alignItems: 'baseline' }}>
          <Col span={24}>
            <div style={{ marginBottom: 10 }}>
              <PrimarySubmit
                label={editCustomer ? 'Edit Customer' : 'Save Customer'}
                loading={loading}
                formName={formName}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  )
}

const CreateCustomerModal = ({ visible, handleOnClose, trucks = [] }) => {
  const { editCustomer } = useSelector((state) => state.createCustomer)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!visible) {
      dispatch(resetGetCustomer())
    }
  }, [dispatch, visible])
  return (
    <StyledModal
      width={500}
      title={<TitleDrawer title={editCustomer ? 'Edit Customer' : 'Create New Customer'} />}
      visible={visible}
      bodyStyle={{ padding: '10px 25px 0px' }}
      onCancel={() => handleOnClose()}
      footer={null}
      destroyOnClose
      afterClose={() => {
        dispatch(resetGetCustomer())
      }}
    >
      <CreateForm trucks={trucks} handleOnClose={handleOnClose} visible={visible} />
    </StyledModal>
  )
}

export default CreateCustomerModal
