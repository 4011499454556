import { put, takeLatest, call } from 'redux-saga/effects'
import { suggestions } from 'services'
import { successGetSuggestions, requestGetSuggestions, failureGetSuggestions } from '../duck/getSuggestions.duck'

function* getGetSuggestions(data) {
  try {
    const response = yield call(suggestions.getSuggestions, data.payload.params)
    yield put(successGetSuggestions(response))
  } catch (error) {
    yield put(failureGetSuggestions(error))
  }
}

function* watchGetAllSuggestionsAsync() {
  yield takeLatest(requestGetSuggestions, getGetSuggestions)
}

export default watchGetAllSuggestionsAsync
