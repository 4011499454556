export default function (api) {
  return{
    getSuggestions: async (params) =>
      api.get('api/suggestions/', {
        params: {
          ...params,
        },
      }),
    deleteSuggestions: async (suggestionId) => api.delete(`api/suggestions/${suggestionId}`),
    saveSuggestions: async (params) =>
      api.post('api/suggestions/save', {
        params: {
          ...params,
        },
      }),
    updateSuggestion: async (params, suggestionId) => 
      api.put(`api/suggestions/${suggestionId}`, {
        params: {
          ...params,
        },
      })
    
  }
}
