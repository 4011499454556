import React from 'react'
import Modal from 'components/Modal'
import Row from 'antd/lib/row'
import Timeline from 'antd/lib/timeline'
import Col from 'antd/lib/col'
import StyledTabs from 'components/Tabs'
import returnStops from 'utils/returnStops'
import { Fuel } from 'assets/svg'
import { removeRulesNoFlatAndMarginZero } from 'utils/filters'
import Details from 'containers/dashboard/CarrierCost/Utils/Details'
import { getMilles } from 'utils/get'
import { useDispatch} from 'react-redux'
import ButtonPrimary from 'components/Button/ButtonPrimary'
import { requestEditSuggestion } from 'store/duck/editSuggestion.duck'
import { TitleValue, TextValue, SmallBox, LargeBox } from './styles'


const convertToNumber = (string = '', fixed = '') => {
  if (typeof string === 'string') {
    return `${Number(Number(string.replace(/[^0-9.-]+/g, '')).toFixed(fixed))}`
  }
  return 0
}

export default function ModalSuggestion({ visible = false, onCancel = () => '', data = {} }) {
  const margin = data?.quoteId?.customerRateInfo?.margin / 100
  const flat = data?.quoteId?.customerRateInfo?.flat
  const dispatch = useDispatch()
  // const quoteExists = "quote" in data && data?.quote != null;

  const { details } = data.quoteId || {}
  const { base_rate: base, ruleAdjustment, multiStepAdjustment, internal_adjustment: gsDatalake } = details || {}
  const { datRate, gsRate, gsPredictionRate } = base || {}
  const totalRulesAdjustments =
    removeRulesNoFlatAndMarginZero(ruleAdjustment).length +
    removeRulesNoFlatAndMarginZero(multiStepAdjustment).length



  const validateDATTooltip = () => {
    if (datRate && datRate.datLow) {
      return datRate.datLow.rate === '$0.00'
    }
    return true
  }
  const validateGS = () => {
    if (gsRate) {
      return gsRate.rate === '$0.00'
    }
    return true
  }

  const inNumber = (string = '', fixed = '') => {
    if (typeof string === 'string') {
      return `${Number(Number(string.replace(/[^0-9.-]+/g, '')).toFixed(fixed))}`
    }
    return 0
  }



  const handleEditSuggestion = async (Fields, SuggestionId) => {
    dispatch(requestEditSuggestion(Fields,SuggestionId))
    onCancel()
  }

  const datRateTotal = validateDATTooltip() ? 0 : inNumber(datRate.total)
  const gsRateTotal = validateGS() ? 0 : inNumber(gsRate?.total)
  const gsPredictionRateTotal = validateGS() ? 0 : inNumber(gsPredictionRate?.total ?? '')
  const rateCost = convertToNumber(details?.rateCost ?? '')
  const estPercentage = `${(((data?.quoteId?.rate - rateCost) / rateCost) * 100).toFixed(1)}` 
  const milles = getMilles(data?.quoteId?.totalMilles,data?.quoteId?.stops) 

  return (
    <Modal onCancel={onCancel} visible={visible} title="View Feedback" width="550px">
      <StyledTabs defaultActiveKey="1" tabBarStyle={{ width: '100%', marginBottom: 10 }}>
        <StyledTabs.TabPane tab="Feedback" key="1">
          <Row span={24}>
              <LargeBox color="#f4f4f5">
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                    <TitleValue style={{ color: '#B0B4C3' }}>Content</TitleValue>
                  </Col>
                  <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                    <TextValue style={{ color: '#464851' }}>{data?.suggestion}</TextValue>
                  </Col>
                </Row>
              </LargeBox>
          </Row>
          <div style={{ margin: '16px 0' }}> </div>
          {data?.sentiment !== "" && 
            <Row span={24}>
                <SmallBox color="#f4f4f5">
                  <Row style={{ display: 'flex', justifyContent: 'center' }}>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                      <TitleValue style={{ color: '#B0B4C3' }}>Sentiment</TitleValue>
                    </Col>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                      <TextValue style={{ color: '#464851' }}>{data?.sentiment}</TextValue>
                    </Col>
                  </Row>
                </SmallBox>
            </Row>
          }
          <div style={{ margin: '16px 0' }}> </div>
          <Row span={24} justify="center">
            <ButtonPrimary
              height="40px"
              onClick={() => handleEditSuggestion({active: !data.active, suggestion: data.suggestion},data._id)}
            >
              {data?.active? 'Resolve': 'Unresolve'}
            </ButtonPrimary>
          </Row>
        </StyledTabs.TabPane>
        {data?.quote && (<>
          <StyledTabs.TabPane tab="Info" key="2">
            <Row gutter={[16, 8]} style={{ paddingTop: '15px' }}>
              <Col span={6}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <SmallBox color="#f4f4f5">
                      <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                          <TitleValue style={{ color: '#B0B4C3' }}>Base Markup</TitleValue>
                        </Col>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                          <TextValue style={{ color: '#464851' }}>${data?.quoteId?.customerRateInfo?.flat}</TextValue>
                        </Col>
                      </Row>
                    </SmallBox>
                  </Col>
                  <Col span={24}>
                    <SmallBox color="#f4f4f5 ">
                      <Row>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                          <TitleValue style={{ color: '#B0B4C3' }}>% Markup</TitleValue>
                        </Col>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                          <TextValue style={{ color: '#464851' }}>{data?.quoteId?.customerRateInfo?.margin}%</TextValue>
                        </Col>
                      </Row>
                    </SmallBox>
                  </Col>
                  <Col span={24}>
                    <SmallBox color="#f4f4f5">
                      <Row>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                          <TitleValue style={{ color: '#B0B4C3' }}>Weight</TitleValue>
                        </Col>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                          <TextValue style={{ color: '#464851' }}>{data?.quoteId?.weight}</TextValue>
                        </Col>
                      </Row>
                    </SmallBox>
                  </Col>
                  <Col span={24}>
                    <SmallBox color="#f4f4f5 ">
                      <Row>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                          <TitleValue style={{ color: '#B0B4C3' }}>Mileage</TitleValue>
                        </Col>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                          <TextValue style={{ color: '#464851' }}>{milles}</TextValue>
                        </Col>
                      </Row>
                    </SmallBox>
                  </Col>
                  <Col span={24}>
                    <SmallBox color="#f4f4f5 ">
                      <Row>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                          <TitleValue style={{ color: '#B0B4C3' }}>Customer</TitleValue>
                        </Col>
                        <Col
                          span={24}
                          style={{ display: 'flex', justifyContent: 'center', textOverflow: 'ellipsis' }}
                        >
                          <TextValue style={{ color: '#464851' }}>{data?.quoteId?.customer?.name}</TextValue>
                        </Col>
                      </Row>
                    </SmallBox>
                  </Col>

                  <Col span={24}>
                    <SmallBox color="#f4f4f5 ">
                      <Row>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                          <TitleValue style={{ color: '#B0B4C3' }}>Pick up Date</TitleValue>
                        </Col>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                          <TextValue style={{ color: '#464851' }}>{data?.quoteId?.pickupDate.slice(0,10)}</TextValue>
                        </Col>
                      </Row>
                    </SmallBox>
                  </Col>

                  <Col span={24}>
                    <SmallBox color="#f4f4f5 ">
                      <Row>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                          <TitleValue style={{ color: '#B0B4C3' }}>Delivery Date</TitleValue>
                        </Col>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                          <TextValue style={{ color: '#464851' }}>{data?.quoteId?.deliveryDate.slice(0,10)}</TextValue>
                        </Col>
                      </Row>
                    </SmallBox>
                  </Col>
                </Row>
              </Col>
              <Col span={18}>
                <Row>
                  <Col
                    span={24}
                    style={{ display: 'flex', backgroundColor: 'white', padding: '10px', borderRadius: '4px' }}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={24} style={{ display: 'flex' }}>
                        <TitleValue>Rate #</TitleValue>
                        <TextValue>{data?.quoteId?._id}</TextValue>
                      </Col>

                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={12}>
                            <TextValue>Equipment Type</TextValue>
                          </Col>

                          <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <TextValue>{data?.quoteId?.truckTypeId?.name}</TextValue>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={12}>
                            <TextValue>Est Carrier Cost</TextValue>
                          </Col>

                          <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <TextValue>{data?.quoteId?.details?.rateCost}</TextValue>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={12}>
                            <TextValue>Est Customer Rate</TextValue>
                          </Col>
                          <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <TextValue>${data?.quoteId?.rate}</TextValue>
                          </Col>
                          <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                marginBottom: '2px',
                              }}
                            >
                              <TextValue style={{ fontSize: '11px', color: 'black' }}>
                                {data?.quoteId?.LH ? `$${data?.quoteId?.LH} (LH)` : ''}
                              </TextValue>
                              {data?.quoteId?.LH ? (
                                <img
                                  alt=""
                                  style={{ marginLeft: '3px', width: '14px', height: '14px', paddingBottom: '1px' }}
                                  src={Fuel}
                                />
                              ) : (
                                ''
                              )}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={12}>
                            <TextValue>Est Margin ($)</TextValue>
                          </Col>
                          <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <TextValue>
                              $
                              {data?.quoteId?.details?.rateCost
                                ? (convertToNumber(data?.quoteId?.details?.rateCost) * margin + flat).toFixed(2)
                                : '0'}
                            </TextValue>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={24}>
                        <Row justify="space-between">
                          <Col span={12}>
                            <TextValue>Est Percentage (%)</TextValue>
                          </Col>
                          <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <TextValue>{data?.quoteId?.details?.rateCost ? estPercentage : ''}%</TextValue>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24} style={{ marginTop: '10px' }}>
                    <TitleValue style={{ paddingBottom: '10px' }}>Lane</TitleValue>
                    <div style={{ padding: '0px 20px' }}>
                      <Timeline style={{ fontSize: '14px' }}>
                        {data.quoteId?.lane &&
                          returnStops(data.quoteId).map((item) => <Timeline.Item key={item}>{item}</Timeline.Item>)}
                      </Timeline>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </StyledTabs.TabPane>
          <StyledTabs.TabPane tab="Details" key="3">
            <Details
              datRate={datRate}
              gsRate={gsRate}
              gsPredictionRate={gsPredictionRate}
              gsDatalake={gsDatalake}
              rateBasic={{ ...details, extraStops: data?.quoteId?.totalStops ?? 0 }}
              datRateTotal={datRateTotal}
              gsRateTotal={gsRateTotal}
              gsPredictionRateTotal={gsPredictionRateTotal}
              ruleAdjustments={ruleAdjustment}
              multiSteptAdjustments={multiStepAdjustment}
              totalRulesAdjusments={totalRulesAdjustments}
              specialServices={data.quoteId?.specialServices ?? []}
            />
          </StyledTabs.TabPane>
        </>)}
      </StyledTabs>
    </Modal>
  )
}
