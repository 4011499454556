import styled from 'styled-components'

export const TitleValue = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #002555;
  text-align: center;
`

export const TextValue = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: #002555;
  white-space: nowrap;
  text-align: center;
`

export const BaseBox = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
`

export const SmallBox = styled(BaseBox)`
  height: 50px;
`

export const LargeBox = styled(BaseBox)`
  min-height: 50px;
  height: auto;
  padding: 16px;
`

export const ContentBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
`