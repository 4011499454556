import React from 'react'
import StyledModal from './styles'

const Modal = ({
  children,
  width = '',
  title,
  visible,
  onCancel,
  padding,
  footer = null,
  height,
  centered = false,
  style,
  bodyStyle,
  zIndex,
}) => {
  const isStyle = style === undefined ? { top: 50 } : style
  return (
    <StyledModal
      footer={footer}
      width={width}
      title={title}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      padding={padding}
      centered={centered}
      style={!centered ? isStyle : {}}
      height={height}
      bodyStyle={bodyStyle}
      maskClosable={false}
      mask={false} 
      zIndex={zIndex}
    >
      {children}
    </StyledModal>
  )
}

export default Modal
