import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Select from 'antd/lib/select'
import Badge from 'antd/lib/badge'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Table from 'components/Table'
import StyledSearch from 'components/Input/Search'
import StyledSelect from 'components/Input/Select'
import FloatLabel from 'components/Label/FloatLabel'
import moment from 'moment'
import { ArrowDownIcon } from 'assets/svg'
import UserNameAvatar from 'components/User/UserNameAvatar'
import { unloadModalSuggestion } from 'store/duck/getModalSuggestion.duck'
import notify from 'utils/notify'
import { requestGetRoles } from 'store/duck/getRoles.duck'
import { requestGetSuggestions } from 'store/duck/getSuggestions.duck'
import DateRange from 'components/Form/DateRange'
import ModalSuggestion from 'components/ModalSuggestions'
import actionsDropdown from './actions'







export default function Feedback() {
  const [form] = Form.useForm()
  const { Option } = Select
  const {roles, successDelete, successUpdate, fetchingDelete} = useSelector((state) => ({
    roles: state.getRoles.data.roles,
    successDelete: state.deleteSuggestion.successDelete,
    successUpdate: state.editSuggestion.success,
    fetchingDelete: state.deleteSuggestion.fetching
  }))
  const { success, error, message, data } = useSelector((state) => state.getSuggestions)
  const {isModalSuggestion, modalSuggestion} = useSelector((state) => state.createModalSuggestion)
  const { xl } = useBreakpoint()
  const [params, setParams] = useState({ page: 1, limit: 12 })
  const dispatch = useDispatch()
  const [feedbacks, setFeedbacks] = useState([])
  const [displayModalSuggestion, setDisplayModalSuggestion] = useState(false)



  
  const getStyle = (active) => {
    const color = '#FFFFFF';
    let bgColor = '#F85359';
    if (active) {
      bgColor = '#00C48C';
    }
    return {
      backgroundColor: bgColor,
      color,
      fontWeight: 400,
      fontSize: 16,
    }
  }

  const getTextRead = (read) => {
    if (read) {
      return 'Read'
    }
    return 'Not Read'
  }

  const getTextResolved = (read) => {
    if (read) {
      return 'Resolved'
    }
    return 'Not Resolved'
  }

  const columns = [
    {
      title: <div style={{ fontSize: '16px' }}>Full Name</div>,
      dataIndex: 'userId',
      key: 'name',
      render: (name, record) => {
        return (
          <UserNameAvatar
            name={`${name.name} ${name.lastName}`}
            avatar={record.userId.avatar}
          />
        )
      },
      width: 250,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Role</div>,
      dataIndex: 'userRole',
      key: 'roleId',
      render: (role) => <div style={{ color: '#545454', fontSize: '16px' }}>{role.name}</div>,
      width: 100,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Date</div>,
      dataIndex: 'createdAt',
      key: 'date',
      render: (date) => <div style={{ fontSize: '16px' }}>{moment(date).format('MM/DD/YYYY HH:mm')}</div>,
      width: 100,
    },
    {
      title: <div style={{ fontSize: '16px' }}>Status</div>,
      dataIndex: 'active',
      key: 'status',
      render: (active,record) => {
        return  <div style={{ display: 'flex' }}><Badge count={getTextResolved(record.active != null?!active:false)}  style= {getStyle(record.active!=null?!active:false)} /></div>
      },
      width: 100,
    },

    {
      title: <div style={{ fontSize: '16px' }}>Viewed</div>,
      dataIndex: 'read',
      key: 'viewed',
      render: (read) => {
        return  <div style={{ display: 'flex' }}><Badge count={getTextRead(read)}  style= {getStyle(read)} /></div>
      },
      width: 100,
    }, 
    {
      ...actionsDropdown(dispatch),
    },
  ]
/*
  const mapNames = (usersApi) => {
    return usersApi.map(
      ({
        _id,
        sentiment,
        suggestion,
        user,
        createdAt,
        updatedAt,
        quoteId,
        userId,
        read,
        active,
        lastLoginDate,
        datRequests,
        customers
      }) => ({
        _id,
        avatar,
        fullName: `${name} ${lastName}`,
        phone,
        email,
        roleId,
        authApis,
        active,
        servicesErrors,
        lastLoginDate,
        datRequests,
        customers
      })
    )
  }
*/

  const onCloseModalSuggestion = useCallback(() => {
    dispatch(unloadModalSuggestion())
    setDisplayModalSuggestion(false)
  }, [dispatch])

  useEffect(() => {
    if (isModalSuggestion) {
      if (modalSuggestion.suggestion) {
        setDisplayModalSuggestion(true)
      } else notify('error', 'No information available')
      dispatch(unloadModalSuggestion())
    }
  }, [isModalSuggestion, modalSuggestion, dispatch])

  useEffect(() => {
    dispatch(requestGetRoles())
  }, [dispatch])

  useEffect(() => {
    dispatch(requestGetSuggestions(params))
  }, [dispatch, params])

  useEffect(() => {
    if (successUpdate) {
      dispatch(requestGetSuggestions(params))
    }
  }, [dispatch, params, successUpdate])
  useEffect(() => {
    if (successDelete) {
      dispatch(requestGetSuggestions(params))
    }
  }, [dispatch, params, successDelete, fetchingDelete])
  // when the filter selections change
  const handleValuesChange = (values) => {
    const active = values.active ?? params.active
    const roleId = values.role ?? params.role
    const dates = values.feedbackDate?.dateStrings? {
      createdAt:values.feedbackDate.dateStrings[0],
      createdAtUntil:values.feedbackDate.dateStrings[1]
    }: undefined;
    setParams({
      ...params,
      text: values.text ?? params.text,
      active: active !== 'All' ? active : undefined,
      roleId,
      ...dates,
    })
  }

  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  useEffect(() => {
    if (success) {
      setFeedbacks(data.data)  // normally map the names before setting feedback
    }
  }, [data.data, dispatch, error, message, success])

  // oupute page components
  return (
    <>
      <ModalSuggestion visible={displayModalSuggestion} onCancel={onCloseModalSuggestion} data={modalSuggestion} />
      <Row gutter={[8, 8]} style={{ width: '96%', margin: '10px auto 15px',paddingTop:"10px" }}>
        <Col span={24}>
          <Row gutter={[16, 0]}>
            <Form
              layout="vertical"
              name="filter-customer-list"
              style={{ width: '100%', margin: '0px', padding: '0px' }}
              form={form}
              initialValues={{
                active: 'All',
                role: 'All',
              }}
              onValuesChange={handleValuesChange}
              hideRequiredMark
            >
              <Row style={{ marginBottom: 0 }} gutter={[24, 16]} justify="end">
                <Col flex={xl ? 8 : undefined} xs={!xl ? 24 : undefined}>
                  <Form.Item name="text">
                    <StyledSearch />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <DateRange label="Feedback Date" name="feedbackDate" allowFutureDates="false" />
                </Col>
                <Col>
                  <FloatLabel label="Role" show>
                    <Form.Item name="role">
                      <StyledSelect
                        style={{ paddingTop: 0, width: 300, paddingLeft: 0 }}
                        suffixIcon={<ArrowDownIcon />}
                        size="large"
                        placeholder="Role"
                      >
                        <Option key="all-roles" value="">
                          All
                        </Option>
                        {roles &&
                          roles.map((role) => (
                            <Option key={role._id} value={role._id}>
                              {role.name}
                            </Option>
                          ))}
                      </StyledSelect>
                    </Form.Item>
                  </FloatLabel>
                </Col>
                <Col>
                  <FloatLabel label="Resolved" show>
                    <Form.Item name="active">
                      <StyledSelect
                        suffixIcon={<ArrowDownIcon />}
                        style={{ paddingTop: 0, width: 300, paddingLeft: 0 }}
                        placeholder="Status"
                        size="large"
                      >
                        <Option value="">All</Option>
                        <Option value="true">Yes</Option>
                        <Option value="false">No</Option>
                      </StyledSelect>
                    </Form.Item>
                  </FloatLabel>
                </Col>
              </Row>
            </Form>
            <Table
              width="100%"
              padding="4px 0px"
              columns={columns}
              data={feedbacks}
              loading={!success}
              rowKey="_id"
              pagination={{ pageSize: 10, total: data.totalRecords, current: params.page }}
              onChange={handleTableChange}
              updatedPagination
              limitPerPage={50}
              
              dispatch={dispatch}
              
              avoidNotify
              
            />
          </Row>
        </Col>
      </Row>
    </>
  )
}
// deleteRequest={requestDeleteSuggestion} used for delete suggestion?
// entityNameForBulk="Feedback" for bulk updates
// rowSelection for bulk selections
