import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  modalSuggestion:{},
  isModalSuggestion:false,
  data: {},  
}

export const {
  requestGetModalSuggestion,
  successGetModalSuggestion,
  failureGetModalSuggestion,
  resetGetModalSuggestion,
  loadModalSuggestion,
  unloadModalSuggestion,
} = createActions({
  REQUEST_GET_MODAL_SUGGESTION: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_GET_MODAL_SUGGESTION: (data) => ({
    data,
    message: '',
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_GET_MODAL_SUGGESTION: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  RESET_GET_MODAL_SUGGESTION: () => ({
    ...defaultState,
  }),
  LOAD_MODAL_SUGGESTION: (data) => ({
    isModalSuggestion: true,
    modalSuggestion: data,
  }),
  UNLOAD_MODAL_SUGGESTION: () => ({
    isModalSuggestion: false,
    modalSuggestion: defaultState.modalSuggestion,
  }),
})

const createModalSuggestion = handleActions(
  {
    [requestGetModalSuggestion]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successGetModalSuggestion]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = false
      draft.data = data
    }),
    [failureGetModalSuggestion]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = false
    }),
    [resetGetModalSuggestion]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [loadModalSuggestion]: produce((draft, { payload: { isModalSuggestion, modalSuggestion } }) => {
      draft.isModalSuggestion = isModalSuggestion
      draft.modalSuggestion = modalSuggestion
    }),
    [unloadModalSuggestion]: produce((draft, { payload: { isModalSuggestion } }) => {
      draft.isModalSuggestion = isModalSuggestion
    }),
  },
  defaultState
)

export default createModalSuggestion
