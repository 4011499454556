import { put, takeLatest, call } from 'redux-saga/effects'
import { suggestions } from 'services'
import {
  requestDeleteSuggestion,
  successDeleteSuggestion,
  failureDeleteSuggestion,
} from '../duck/deleteSuggestion.duck'

function* deleteSuggestionAsync(data) {
  try {
    const response = yield call(suggestions.deleteSuggestions, data.payload.itemId)
    yield put(successDeleteSuggestion(response))
  } catch (error) {
    yield put(failureDeleteSuggestion(error))
  }
}

function* watchDeleteSuggestionAsync() {
  yield takeLatest(requestDeleteSuggestion, deleteSuggestionAsync)
}

export default watchDeleteSuggestionAsync
