import Menu from 'antd/lib/menu'
import React, { useState} from 'react'
import { useDispatch } from 'react-redux'
import MoreDropdown from 'components/MoreDropdown'
import ModalConfirmation from 'components/ModalConfirmation'
import { MdBlock, MdDelete } from 'react-icons/md'
import { IoMdDocument} from 'react-icons/io'
import { AiOutlineCheck } from 'react-icons/ai'
import { requestEditSuggestion } from 'store/duck/editSuggestion.duck'
import { requestDeleteSuggestion } from 'store/duck/deleteSuggestion.duck'
import { loadModalSuggestion } from 'store/duck/getModalSuggestion.duck'
import { StyledCol, StyledRow } from './styles'



const sizeIcon = '20'
const paddingItem = { padding: '0px 8px 0px 8px' }

const ActionsDropdown = ({ record }) => {
  const dispatch = useDispatch()
 
  const loadSuggestion = React.useCallback(() => {
    dispatch(loadModalSuggestion(record))
    dispatch(requestEditSuggestion({read: true, suggestion: record.suggestion}, record._id))
  }, [record, dispatch])

  const [propsModal, setPropsModal] = useState({
    visible: false,
    message: 'Are you sure you want to delete this User?',
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <MdDelete {...props} />,
    textButon: 'Delete',
    textSuccessfull: 'User has been deleted',
    actionConfirm: (some) => some,
  })

  const handleEditSuggestion = async (Fields, SuggestionId) => {
    dispatch(requestEditSuggestion(Fields,SuggestionId))
  }

  const handleDeleteSuggestion = async (suggestionId) => {
    setPropsModal({
      visible: true,
      message: 'Are you sure you want to delete this Feedback?',
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon: (props) => <MdDelete {...props} />,
      textButon: 'Delete',
      textSuccessfull: 'Feedback has been deleted',
      actionConfirm: () => {
        dispatch(requestDeleteSuggestion(suggestionId))

        setPropsModal({
          visible: false,
          message: ' ',
          icon: (props) => props,
          textButon: '',
          textSuccessfull: '',
          actionConfirm: (props) => props,
        })
      },
    })
  }

  return (
    <MoreDropdown
      menu={
        <Menu>
          <Menu.Item style={paddingItem} onClick={loadSuggestion}>
            <StyledRow>
              <StyledCol>
                <IoMdDocument size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Open</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <Menu.Item style={paddingItem} onClick={() => handleEditSuggestion({active: !record.active, suggestion: record.suggestion},record._id)}>
            <StyledRow>
              <StyledCol>
                {(record.active??true)?<AiOutlineCheck size={sizeIcon} />:<MdBlock size={sizeIcon} />}
              </StyledCol>
              <StyledCol>
                <span>{(record.active??true)?'Resolve':'Unresolve'}</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <Menu.Item style={paddingItem} onClick={() => { 
              return handleEditSuggestion({read: !record.read, suggestion: record.suggestion},record._id)
            }}>
            <StyledRow>
              <StyledCol>
                {((!record.read)??true)?<AiOutlineCheck size={sizeIcon} />:<MdBlock size={sizeIcon} />}
              </StyledCol>
              <StyledCol>
                <span>{(record.read??false)?'Mark Unread':'Mark Read'}</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>

          <Menu.Item style={paddingItem} onClick={() => handleDeleteSuggestion(record._id)}>
            <StyledRow>
              <StyledCol>
                <MdDelete size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Delete Feedback</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <ModalConfirmation
            avoidNotify
            actionConfirm={propsModal.actionConfirm}
            textSuccessfull={propsModal.textSuccessfull}
            textButton={propsModal.textButon}
            close={() => {
              setPropsModal({
                visible: false,
                message: ' ',
                icon: (props) => props,
                textButon: '',
                textSuccessfull: '',
                actionConfirm: (props) => props,
              })
            }}
            icon={propsModal.icon}
            message={propsModal.message}
            visible={propsModal.visible}
            height="296px"
            width={350}
            centered
          />
        </Menu>
      }
    />
  )
}

const actionsDropdown = (_, openDrawer) => ({
  title: <div style={{ fontSize: '16px' }}>Actions </div>,
  dataIndex: 'actions',
  key: 'actions',
  textWrap: 'word-break',
  render: (edit, record) => <ActionsDropdown record={record} openDrawer={openDrawer} />,
  align: 'center',
  width: 100,
})

export default actionsDropdown
