import PropTypes from 'prop-types'
import Modal from './Modal'

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  width: PropTypes.number,
  padding: PropTypes.string,
  height: PropTypes.string,
  zIndex: PropTypes.number,
}

Modal.defaultProps = {
  width: 910,
  padding: 'auto',
  height: 'auto',
}

export default Modal
