import produce from 'immer'
import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  message: '',
  fetching: false,
  error: false,
  success: false,
  data: {},

}

export const {
  requestCreateSuggestion,
  successCreateSuggestion,
  failureCreateSuggestion,
  clearCreateSuggestion,
} = createActions({
  REQUEST_CREATE_SUGGESTION: (data) => ({
    ...defaultState,
    fetching: true,
    error: false,
    success: false,
    data,
  }),
  SUCCESS_CREATE_SUGGESTION: (data) => ({
    data,
    message: data.message,
    fetching: false,
    error: false,
    success: true,
  }),
  FAILURE_CREATE_SUGGESTION: (message) => ({
    message,
    fetching: false,
    error: true,
    success: false,
  }),
  CLEAR_CREATE_SUGGESTION: () => ({
    ...defaultState,
  }),

})

const createSpecialService = handleActions(
  {
    [requestCreateSuggestion]: produce((draft, { payload: { fetching, data, error, success } }) => {
      draft.data = data
      draft.fetching = fetching
      draft.error = error
      draft.success = success
    }),
    [successCreateSuggestion]: produce((draft, { payload: { message, fetching, success, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.success = success
      draft.error = false
      draft.data = data
    }),
    [failureCreateSuggestion]: produce((draft, { payload: { message, fetching, error } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = false
    }),
    [clearCreateSuggestion]: produce((draft, { payload: { message, fetching, error, data } }) => {
      draft.message = message
      draft.fetching = fetching
      draft.error = error
      draft.success = false
      draft.data = data
    }),
  },
  defaultState
)

export default createSpecialService
